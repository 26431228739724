<template>
<!-- 关注商品 -->
<div class='attention'>
  <div class="title">
    <ul class="title_ul">
      <p class="ul_p">筛选</p>
      <li class="title_li">全部</li>
      <!-- <el-button class="title_caozuo" size="small">批量管理</el-button> -->
    </ul>
  </div>
  <div class="att_body" v-loading="loading" element-loading-text="正在查询...">
    <ul class="cont_ul">
      <li class="cont_li" v-for="(i,k) in attDataList" :key="k"
        @click="toDetail(i)"
        @mouseover="showunfollow=k"
        @mouseout="showunfollow=''">
        <div class="unfollow" v-show="showunfollow===k" @click.stop="clickUnfollow(i, k)">
          <el-icon size="20" color="#ff0000"><RemoveFilled /></el-icon>
          取消关注
        </div>
        <div class="gzcont">
          <span class="gz_head">
            <!-- // previewStatus 现货：0  预售：1 竞拍：2  易货：3 转售：4 -->
            {{i.previewStatus==0?'现货':
            i.previewStatus==1?'预售':
            i.previewStatus==2?'竞拍':
            i.previewStatus==3?'易货':
            i.previewStatus==4?'转售':''}}
          </span>
          <img :src="i.pic" alt="">
          <div class="gz_foot" v-if="i.dateStatus==0||i.publishStatus==0||i.stock==0">
            <!-- <p>比关注时降价<span>￥100.0</span></p> -->
            <p v-if="i.dateStatus==0">此商品已过期</p>
            <p v-else-if="i.publishStatus==0">此商品已下架</p>
            <p v-else-if="i.stock==0">此商品已无库存</p>
          </div>
        </div>
        <p class="name">{{i.name}}</p>
        <p class="price">￥{{i.price}}<span> /kg</span></p>
      </li>
      <div class="nodata" v-if="attDataList.length == 0">
        <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
      </div>
    </ul>
  </div>
  <div class="showfooter" v-if="totalnum<pagesize?false:true">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalnum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue-demi'
import http from '../../../api/http'
import { ElMessage } from 'element-plus'
// 查询关注商品
const queryPmsFollowTheProductByPage = (data) => {
  return http.get('/mall-portal/mall/pmsfollowtheproduct/queryPmsFollowTheProductByPage', data)
}
// 取消关注商品
const pmsfollowtheproduct = (data) => {
  return http.post('mall-portal/mall/pmsfollowtheproduct/delete', data, true)
}
export default {
  name: 'Attention',
  data () {
    return {
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(8),
      // 默认展示页
      currentPage: ref(1),

      attDataList: ref([]), // 关注数据
      showunfollow: '', // 取关是否展示
      loading: ref(true)
    }
  },
  components: {},
  methods: {
    async clickUnfollow (e, index) {
      console.log(e)
      const data = [e.id]
      const res = await pmsfollowtheproduct(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
        this.attDataList.splice(index, 1)
      } else {
        ElMessage.warning({
          message: '取关失败'
        })
      }
    },
    async initDataList () {
      const data = {
        pageNum: this.currentPage,
        pageSize: 8
      }
      const res = await queryPmsFollowTheProductByPage(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.attDataList = res.data.data.list
        this.totalnum = res.data.data.total
        this.loading = false
      } else {
        ElMessage.warning({
          message: '查询失败'
        })
      }
    },
    toDetail (item) {
      console.log(item)
      if (item.dateStatus === 0 || item.publishStatus === 0 || item.stock === 0) {
        ElMessage.warning({
          message: '此商品已经无法购买'
        })
        return
      }
      switch (item.previewStatus) {
        case 0:
          this.$router.push({
            path: '/cashcommodity/varietiy_details',
            query: {
              productSn: item.productSn
            }
          })
          break
        case 1:
          this.$router.push({
            path: '/reservation/varietiy_details',
            query: {
              productSn: item.productSn
            }
          })
          break
        case 2:
          this.$router.push({
            path: '/auction/englishauction',
            query: {
              id: item.auctionProductId
            }
          })
          break
        case 3:
          this.$router.push({
            path: '/barter/detail',
            query: {
              id: item.exchangeProductId
            }
          })
          break
        case 4:
          this.$router.push({
            path: '/resell/resell_details',
            query: {
              id: item.resaleProductId
            }
          })
          break
        default:
          break
      }
    }
  },
  mounted () {
    this.initDataList()
  },
  setup (props, ctx) {
    const datacop = getCurrentInstance()
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    const handleCurrentChange = (val) => {
      console.log(`current page: ${val}`)
      datacop.data.loading = true
      datacop.data.currentPage = val
      initData()
    }
    const initData = async (page) => {
      const data = {
        pageNum: datacop.data.currentPage,
        pageSize: 8
      }
      const res = await queryPmsFollowTheProductByPage(data)
      console.log(res)
      if (res.data.code === 200) {
        datacop.data.attDataList = res.data.data.list
        datacop.data.totalnum = res.data.data.total
        datacop.data.loading = false
      }
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      initData
    }
  }
}
</script>
<style lang="scss">
.attention{
  .showfooter{
    height: 100px;
    // background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.attention{
  font-family: Microsoft YaHei;
  .title{
    display: flex;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ebebeb;
    .title_ul{
      display: flex;
      align-items: center;
      padding: 0 20px;
      .ul_p{
        font-size: 18px;
        font-weight: bold;
        color: #666666;
      }
      .title_li{
        color: #333333;
        font-size: 16px;
        margin: 0 50px;
      }
      .title_caozuo{
        flex: 1;
        // text-align: end;
        height: 20px;
      }
    }
  }
  .att_body{
    min-height: 666px;
    .cont_ul{
      padding: 10px;
      display: flex;
      flex-flow: wrap;
      .cont_li{
        padding: 18px;
        width: 25%;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .unfollow{
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          // transition: all 1s;
          .el-icon{
            margin-right: 5px;
          }
          cursor: pointer;
          z-index: 5;
          width: 100%;
          height: 55px;
          background: #000000;
          opacity: 0.3;
          color: #ffffff;
          line-height: 55px;
          font-size: 20px;
          text-align: center;
          overflow: hidden;
        }
        .gzcont{
          position: relative;
          width: 220px;
          height: 220px;
          // display: flex;
          // flex-flow: wrap;
          .gz_head{
            width: 30px;
            height: 20px;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            line-height: 20px;
            background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
            border-radius: 0px 0px 4px 0px;
            position: absolute;
            top: 0;
          }
          img{
            width: 220px;
            height: 220px;
          }
          .gz_foot{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 32px;
            background: #00000027;
            color: #ffffff;
            line-height: 32px;
            text-align: center;
          }
        }
        .name{
          font-size: 16px;
          line-height: 50px;
          height: 50px;
          color: #333333;
        }
        .price{
          color: #EE313E;
          font-size: 17px;
          font-weight: 500;
          span{
            color: #999999;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
