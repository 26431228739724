<template>
<div class='cont'>
  <h2>{{$t('m.personalcenter_myorder.Invoice_management')}}</h2>
  <div class="body">
    <span class="addspan" @click="adddialogVisible = true">{{$t('m.personalcenter_myorder.Add_an_invoice')}}</span>
    <div class="flexul">
      <ul class="body_ul">
      <li class="body_li"
       v-for="(i,k) in invoiceList" :key="k">
      <el-card>
        <div>
          <span class="isact" v-show="k==0&&i.defaultStatus==1">{{$t('m.personalcenter_myorder.tacitly_approve')}}</span>
          <div class="bottom">
            <p class="msg">{{$t('m.buy.Invoice_type')}}： <span>{{i.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</span></p>
            <p class="msg">{{$t('m.buy.invoice_title')}}： <span>{{i.billHeader}}</span></p>
            <p class="msg">{{$t('m.buy.check_taker')}}： <span>{{i.billReceiverName}}</span></p>
            <p class="msg">{{$t('m.buy.Ticket_collection_telephone')}}： <span>{{i.billReceiverPhone}}</span></p>
            <p class="msg">{{$t('m.buy.Receipt_address')}}： <span>{{i.billReceiverAddress}}</span></p>
            <p class="msg" v-if="i.billType!=0&&showall">{{$t('m.buy.Tariff_Item')}}： <span>{{i.billCode}}</span></p>
            <p class="msg" v-if="i.billType!=0&&showall">{{$t('m.buy.deposit_bank')}}： <span>{{i.billBank}}</span></p>
            <p class="msg" v-if="i.billType!=0&&showall">{{$t('m.buy.account_number')}}： <span>{{i.billBankAccount}}</span></p>
            <p class="msg" v-if="i.billType!=0&&showall">{{$t('m.buy.Account_phone_number')}}： <span>{{i.billPhone}}</span></p>
            <p class="msg" v-if="i.billType!=0&&showall">{{$t('m.buy.business_address')}}： <span>{{i.billAddress}}</span></p>
          </div>
        </div>
        <div class="bottom_btn">
          <el-button size="small" round
          @click="changeaddress(i, k),upDatadialogVisible = true">{{$t('m.personalcenter_myorder.redact')}}</el-button>
          <el-button size="small" round class="remove"
          @click.stop="removedialogVisible = true">{{$t('m.buy.delete')}}</el-button>
        </div>
      </el-card>
      </li>
      <span class="showallsp" v-if="invoiceList != null"
      @click="this.showall=!showall"
      >{{showall?$t('m.personalcenter_myorder.Pack_up_all'):$t('m.personalcenter_myorder.Full_expansion')}}
        <el-icon>
          <component :is="showall?'ArrowUpBold':'ArrowDownBold'"></component>
        </el-icon>
      </span>
      <div class="nodata" v-if="invoiceList == null">
        <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
      </div>
    </ul>
    </div>
  </div>
</div>
     <!-- 新增发票 -->
    <el-dialog
      v-model="adddialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.Additional_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <!-- <div class="title">新增发票</div> -->
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Invoice_type')}}：</div>
          <div class="zyitem-right">
            <el-radio-group v-model="addindex" size="small"
            @change="changListNow">
              <el-radio-button label="0">{{$t('m.buy.plain_invoice')}}</el-radio-button>
              <el-radio-button label="1">{{$t('m.buy.vat_special_invoice')}}</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.invoice_title')}}：</div>
          <div class="zyitem-right">
            <el-input
              type="text"
              v-model="addinvoicemessage.billHeader"
              :placeholder="$t('m.buy.Please_enter_the_invoice_title')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.taxpayer_identification_number')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billCode"
              :placeholder="$t('m.buy.Please_enter_taxpayer_identification_number')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.check_taker')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverName"
              :placeholder="$t('m.buy.Please_enter_check_taker')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Ticket_collection_telephone')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverPhone"
              :placeholder="$t('m.buy.Please_enter_Ticket_collection_telephone')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Receipt_address')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="addinvoicemessage.billReceiverAddress"
              :placeholder="$t('m.buy.Please_enter_Receipt_address')"
            ></el-input>
          </div>
        </div>
        <!-- 增值税专用发票 -->
        <div v-show="addindex == 1">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.deposit_bank')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billBank"
                :placeholder="$t('m.buy.Please_enter_deposit_bank')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.account_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billBankAccount"
                :placeholder="$t('m.buy.Please_enter_account_number')"
              ></el-input>
            </div>
          </div>

          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.Account_phone_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billPhone"
                maxlength="11"
                :placeholder="$t('m.buy.Please_enter_Account_phone_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.business_address')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="addinvoicemessage.billAddress"
                :placeholder="$t('m.buy.Please_enter_business_address')"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="default-invoice">
          <div class="labels">{{$t('m.buy.Set_as_the_default_invoice')}}</div>
          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
        <el-button @click="addptsub" v-show="addindex == 0" class="save">
          {{$t('m.buy.Save_and_Use_plain')}}
        </el-button>
        <el-button @click="addptsub" v-show="addindex == 1" class="save">
          {{$t('m.buy.Save_and_Use')}}
        </el-button>
      </div>
    </el-dialog>
     <!-- 删除发票 -->
    <el-dialog
      v-model="removedialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.Deletion_of_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="contremove"
         v-for="(i,k) in invoiceList" :key="k">
          <span class="span_show_left">{{$t('m.buy.Invoice_type')}}：{{i.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</span>
          <span class="span_show_center">{{$t('m.buy.invoice_title')}}：{{i.billHeader}}</span>
          <el-button size="small" @click="deleteinvoice(i.id,k)">{{$t('m.buy.delete')}}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改发票 -->
    <el-dialog
      v-model="upDatadialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.buy.amend_an_invoice')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Invoice_type')}}：</div>
          <div class="zyitem-right">{{upDatainvoicemessage.billType==0?$t('m.buy.plain_invoice'):$t('m.buy.vat_special_invoice')}}</div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.invoice_title')}}：</div>
          <div class="zyitem-right">
            <el-input
              type="text"
              v-model="upDatainvoicemessage.billHeader"
              :placeholder="$t('m.buy.Please_enter_the_invoice_title')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.taxpayer_identification_number')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billCode"
              :placeholder="$t('m.buy.Please_enter_taxpayer_identification_number')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.check_taker')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverName"
              :placeholder="$t('m.buy.Please_enter_check_taker')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Ticket_collection_telephone')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverPhone"
              :placeholder="$t('m.buy.Please_enter_Ticket_collection_telephone')"
            ></el-input>
          </div>
        </div>
        <div class="zyitem">
          <div class="zyitem-left">{{$t('m.buy.Receipt_address')}}：</div>
          <div class="zyitem-right">
            <el-input
              v-model="upDatainvoicemessage.billReceiverAddress"
              :placeholder="$t('m.buy.Please_enter_Receipt_address')"
            ></el-input>
          </div>
        </div>
        <!-- 增值税专用发票 -->
        <div v-show="addindex == 1">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.deposit_bank')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billBank"
                :placeholder="$t('m.buy.Please_enter_deposit_bank')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.account_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billBankAccount"
                :placeholder="$t('m.buy.Please_enter_account_number')"
              ></el-input>
            </div>
          </div>

          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.Account_phone_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billPhone"
                :placeholder="$t('m.buy.Please_enter_Account_phone_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.buy.business_address')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="upDatainvoicemessage.billAddress"
                :placeholder="$t('m.buy.Please_enter_business_address')"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="default-invoice">
          <div class="labels">{{$t('m.buy.Set_as_the_default_invoice')}}</div>
          <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
        <el-button @click="changeInvoice" v-show="addindex == 0" class="save">
          {{$t('m.buy.Save_and_Use_plain')}}
        </el-button>
        <el-button @click="changeInvoice" v-show="addindex == 1" class="save">
          {{$t('m.buy.Save_and_Use')}}
        </el-button>
      </div>
    </el-dialog>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'

// 删除发票信息
const deleteinvoicefun = (id, data) => {
  return http.get(`mall-order/bill/delBill/${id}`, data)
}
// 修改发票信息
const updBill = (data) => {
  return http.post('/mall-order/bill/updBill', data)
}
export default {
  name: 'AreaList',
  data () {
    return {
      value: true,
      iscut: 0,
      invoiceList: ref([]),
      adddialogVisible: false, // 新增发票弹窗开关
      removedialogVisible: false, // 删除发票开关
      upDatadialogVisible: false, // 修改发票开关
      list: [{ name: '普通发票' }, { name: '增值税专用发票' }],
      changeindex: 0,
      addindex: 0,
      invoicevos: [],
      invoicemessage: '',
      changeinvoicemessage: '',
      invoicelist: [],
      addinvoicemessage: {
        billBank: '',
        billHeader: '',
        billCode: '',
        billBankAccount: '',
        billPhone: '',
        billAddress: '',
        billReceiverName: '',
        billReceiverPhone: '',
        billReceiverAddress: ''
      },
      upDatainvoicemessage: reactive({}),
      showall: false
    }
  },
  components: {},
  methods: {
    cutinvoice (i, k) {
      this.iscut = i
    },
    changListNow (e) {
      console.log(e)
    },
    async addptsub () { // 新增发票
      let data = {}
      let status = 0
      if (this.addindex === 0) {
        const fptitle = this.addinvoicemessage.billHeader
        const spnumber = this.addinvoicemessage.billCode

        const billReceiverName = this.addinvoicemessage.billReceiverName
        const billReceiverPhone = this.addinvoicemessage.billReceiverPhone
        const billReceiverAddress = this.addinvoicemessage.billReceiverAddress
        if (fptitle === '' ||
         spnumber === '' ||
         billReceiverName === '' ||
         billReceiverPhone === '' ||
         billReceiverAddress === '') {
          ElMessage.error({
            message: '请完善相关信息'
          })
          console.log(this.addinvoicemessage)
          return
        }
        this.value ? (status = 1) : (status = 0)
        const usermessage = getStore('usermessage')
        const uid = JSON.parse(usermessage).id
        console.log(uid)
        data = {
          memberId: uid,
          billType: 0,
          billHeader: fptitle,
          billCode: spnumber,
          defaultStatus: status,
          billReceiverName: billReceiverName,
          billReceiverPhone: billReceiverPhone,
          billReceiverAddress: billReceiverAddress
        }
      } else {
        const fptitle = this.addinvoicemessage.billHeader
        const spnumber = this.addinvoicemessage.billCode
        this.value ? (status = 1) : (status = 0)
        const bank = this.addinvoicemessage.billBank
        const banknumber = this.addinvoicemessage.billBankAccount
        const bankphone = this.addinvoicemessage.billPhone
        const registeraddress = this.addinvoicemessage.billAddress
        const billReceiverName = this.addinvoicemessage.billReceiverName
        const billReceiverPhone = this.addinvoicemessage.billReceiverPhone
        const billReceiverAddress = this.addinvoicemessage.billReceiverAddress
        if (
          fptitle === '' ||
          spnumber === '' ||
          bank === '' ||
          banknumber === '' ||
          bankphone === '' ||
          registeraddress === '' ||
          billReceiverName === '' ||
          billReceiverPhone === '' ||
          billReceiverAddress === ''
        ) {
          ElMessage.error({
            message: '请完善相关信息'
          })
          console.log(this.addinvoicemessage)
          return
        }
        if (!/^1[345789]\d{9}$/.test(bankphone)) {
          ElMessage.warning({
            message: '手机号不正确'
          })
          return
        }
        const usermessage = getStore('usermessage')
        const uid = JSON.parse(usermessage).id
        data = {
          memberId: uid,
          billType: 1,
          billHeader: fptitle,
          billCode: spnumber,
          defaultStatus: status,
          billBank: bank,
          billBankAccount: banknumber,
          billPhone: bankphone,
          billAddress: registeraddress,
          billReceiverName: billReceiverName,
          billReceiverPhone: billReceiverPhone,
          billReceiverAddress: billReceiverAddress
        }
      }
      console.log(this.addinvoicemessage)

      this.adddialogVisible = false
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })

      try {
        http.post('mall-order/bill/addBill', data)
          .then(res => {
            console.log(res)
            loading.close()
            if (res.data.flag) {
              ElMessage.success({
                message: '添加成功'
              })
              this.invoicemessage = res.data.data
              this.$store.state.invoice = res.data.data
              this.addinvoicemessage = {
                billBank: '',
                billHeader: '',
                billCode: '',
                billBankAccount: '',
                billPhone: '',
                billAddress: '',
                billReceiverName: '',
                billReceiverPhone: '',
                billReceiverAddress: ''
              }
              console.log(this.addinvoicemessage)
              this.getlookinvoicelist()
            }
          }).catch(err => {
            loading.close()
            console.log(err)
          })
      } catch (e) {
        loading.close()
      }
    },
    async getlookinvoicelist () {
      http.get('mall-order/bill/getBillList')
        .then(res => {
          console.log(res)
          this.invoiceList = res.data.data
          if (this.invoiceList == null) {
            ElMessage.error({
              message: '请添加发票！'
            })
            return
          }
          this.iscut = this.invoiceList[0].id
          // emitter.emit('invoiceMsg', this.iscut)
        }).catch(err => {
          console.log(err)
        })
    },
    async deleteinvoice (id, index) { // 删除发票
      this.$confirm('您确定删除此发票吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const res = deleteinvoicefun(id, {})
        res.then(succ => {
          if (succ.data.code === 200) {
            ElMessage.success({ message: succ.data.msg })
            this.removedialogVisible = false
            this.invoiceList.splice(index, 1)
          }
        }).catch(err => {
          console.log(err)
        })
      })
        .catch(() => {})
    },
    changeaddress (item, index) { // 修改发票数据
      console.log(item)
      this.addindex = item.billType
      this.upDatainvoicemessage = item
      this.upDatainvoicemessage.defaultStatus === 1 ? this.value = true : this.value = false
      // console.log(this.upDatainvoicemessage.defaultStatus)
    },
    async changeInvoice () {
      console.log(this.upDatainvoicemessage)
      let status = 0
      this.value ? (status = 1) : (status = 0)
      const data = {
        id: this.upDatainvoicemessage.id,
        billHeader: this.upDatainvoicemessage.billHeader,
        billBank: this.upDatainvoicemessage.billBank,
        billBankAccount: this.upDatainvoicemessage.billBankAccount,
        billPhone: this.upDatainvoicemessage.billPhone,
        billCode: this.upDatainvoicemessage.billCode,
        billContent: this.upDatainvoicemessage.billContent,
        billAddress: this.upDatainvoicemessage.billAddress,
        billType: this.upDatainvoicemessage.billType,
        billReceiverName: this.upDatainvoicemessage.billReceiverName,
        billReceiverPhone: this.upDatainvoicemessage.billReceiverPhone,
        billReceiverAddress: this.upDatainvoicemessage.billReceiverAddress,
        defaultStatus: status
      }
      const res = await updBill(data)
      console.log(res)
      if (res.data.code === 200) {
        this.getlookinvoicelist()
        this.upDatadialogVisible = false
        this.addindex = 0
        this.upDatainvoicemessage = {}
      }
    }
  },
  mounted () {
    this.getlookinvoicelist()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../style/viriables.scss';
.cont{
  font-family: Microsoft YaHei;
  h2{
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    color: $title;
  }
  .body{
    .addspan{
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #ebebeb;
      color: $title;
      cursor: pointer;
    }
    .flexul{
      .body_ul{
        display: flex;
        flex-wrap: wrap;
        .body_li{
          flex-shrink: 0;
          float: left;
          width: 50%;
          padding: 20px;
          box-sizing: border-box;
          .el-card{
            margin: 0 auto;
            width: 400px;
            min-height: 210px;
            .el-card__body{
              div{
                // display: flex;
                // flex-flow: column;
                .isact{
                  // display: inline-block;
                  min-width: 60px;
                  padding: 0 5px;
                  height: 30px;
                  display: block;
                  float: right;
                  background: $title;
                  color: #ebebeb;
                  border-radius: 10px;
                  line-height: 30px;
                  text-align: center;
                }
                .bottom{
                  // margin-top: 20px;
                  display: flex;
                  flex-flow: column;
                  .msg{
                    line-height: 40px;
                  }
                }
              }
              .bottom_btn{
                margin-top: 20px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .el-button{
                  width: 90px;
                }
                .remove{
                  background: $title;
                  color: #ebebeb;
                }
              }
            }
          }
        }
        .showallsp{
          width: 100%;
          margin: 50px 0;
          box-sizing: border-box;
          cursor: pointer;
          text-align: center;
          // margin-left: 70px;
          font-size: 25px;
          color: #999999;
        }
      }
    }
  }
}
.el-overlay{
  .el-overlay-dialog{
    .el-dialog{
      .el-dialog__header{
        .el-dialog__title{
          width: 100%;
          display: inline-block;
          text-align: center;
        }
      }
      .el-dialog__body{
        font-size: 18px;
        .addfps{
          padding:0 40px;
          line-height: 50px;
          .zyitem{
            display: flex;
            align-items: center;
            .zyitem-left{
              width: 100px;
              display: inline-block;
              text-align-last: justify;
            }
            .zyitem-right{
              flex: 1;
            }
          }
          .contremove{
            border: 1px solid #c6824e;
            background: #ffeee0;
            display: flex;
            align-items: center;
            margin: 5px 0;
            padding: 0 20px;
            .span_show_left{
              width: 200px;
            }

            .span_show_center{
              margin: 0 20px;
              flex: 1;
            }
            .el-button{
              height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
