<template>
<div class='cont'>
  <h2>{{$t('m.personalcenter_myorder.shipping_address')}}</h2>
  <div class="body">
    <span class="addspan" @click="dialogVisible = true">{{$t('m.personalcenter_myorder.Add_a_shipping_address')}}</span>
    <ul class="body_ul">
      <li class="body_li"
       v-for="(item,index) in datalist" :key="index">
      <el-card>
        <div>
          <span class="isact" v-show="index==0">{{$t('m.personalcenter_myorder.tacitly_approve')}}</span>
          <div class="bottom">
            <p class="msg">{{$t('m.personalcenter_myorder.name')}}： <span>{{item.name}}</span></p>
            <p class="msg">{{$t('m.personalcenter_myorder.phone_number')}}： <span>{{item.phoneNumber}}</span></p>
            <p class="msg">{{$t('m.personalcenter_myorder.shipping_address')}}： <span>{{item.detailAddress}}</span></p>
          </div>
        </div>
        <div class="bottom_btn">
          <el-button size="small" round
          @click="dialogAmend = true,changeaddress(item)">{{$t('m.personalcenter_myorder.redact')}}</el-button>
          <el-button size="small" round class="remove"
          @click="deleteaddress(item.id, index)">{{$t('m.personalcenter_myorder.delete')}}</el-button>
        </div>
      </el-card>
      </li>
      <div class="nodata" v-if="datalist.length == 0">
        <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
      </div>
    </ul>
  </div>
</div>

<!-- 新增收货地址弹窗 -->
<el-dialog v-model="dialogVisible"
:title="$t('m.personalcenter_myorder.New_shipping_address')"
  width="40%">
  <div class="addsite">
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.consignee_name')}}</div>
      <div class="add-item-right">
        <el-input v-model="name"
        :placeholder="$t('m.personalcenter_myorder.Please_enter_consignee_name')"></el-input>
      </div>
    </div>
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.phone_number')}}</div>
      <div class="add-item-right">
        <el-input
          v-model="phone"
          :placeholder="$t('m.personalcenter_myorder.Please_enter_mobile_phone_number')"
        ></el-input>
      </div>
    </div>
    <!-- <div class="add-item"> // 三级联动数据
      <div class="add-item-left">所在地区</div>
      <div class="add-item-right">
        <el-cascader
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="addressChange"
        >
        </el-cascader>
      </div>
    </div> -->
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.detailed_address')}}</div>
      <div class="add-item-right">
        <el-input
          v-model="detailaddress"
          :placeholder="$t('m.personalcenter_myorder.Please_enter_detailed_address')"
        ></el-input>
      </div>
    </div>
    <div class="chose">
      <el-switch v-model="showallgg" inline-prompt
        :active-text="$t('m.personalcenter_myorder.be')"
        :inactive-text="$t('m.personalcenter_myorder.not')"
      >
        </el-switch>
        {{$t('m.personalcenter_myorder.Is_it_set_to_default_address')}}
    </div>
    <div class="footbtn">
      <el-button @click="sub" class="save">{{$t('m.personalcenter_myorder.Save_and_use')}}</el-button>
    </div>
  </div>
</el-dialog>

<!-- 修改收货地址弹窗 -->
<el-dialog v-model="dialogAmend"
title = "修改收货地址"
  width="40%">
  <div class="addsite">
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.consignee_name')}}</div>
      <div class="add-item-right">
        <el-input v-model="changeaddressmes.name"
        :placeholder="$t('m.personalcenter_myorder.Please_enter_consignee_name')"></el-input>
      </div>
    </div>
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.phone_number')}}</div>
      <div class="add-item-right">
        <el-input
          v-model="changeaddressmes.phoneNumber"
          :placeholder="$t('m.personalcenter_myorder.Please_enter_mobile_phone_number')"
        ></el-input>
      </div>
    </div>
    <!-- <div class="add-item"> // 三级联动数据
      <div class="add-item-left">所在地区</div>
      <div class="add-item-right">
        <el-cascader
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="addressChange"
        >
        </el-cascader>
      </div>
    </div> -->
    <div class="add-item">
      <div class="add-item-left">{{$t('m.personalcenter_myorder.detailed_address')}}</div>
      <div class="add-item-right">
        <el-input
          v-model="changeaddressmes.detailAddress"
          :placeholder="$t('m.personalcenter_myorder.Please_enter_detailed_address')"
        ></el-input>
      </div>
    </div>
     <div class="chose">
      <el-switch v-model="changeaddressmes.defaultStatus"
      inline-prompt
      :active-text="$t('m.personalcenter_myorder.be')"
      :inactive-text="$t('m.personalcenter_myorder.not')" @change="aaaaa">
        </el-switch>
        {{$t('m.personalcenter_myorder.Is_it_set_to_default_address')}}
    </div>
    <div class="footbtn">
      <el-button @click="modifyaddress" class="save">{{$t('m.personalcenter_myorder.Save_and_use')}}</el-button>
    </div>
  </div>
</el-dialog>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import { getStore } from '../../tools/storage'
export default {
  name: 'AreaList',
  data () {
    return {
      // datalist: [{}, {}, {}],
      value: true,
      showallgg: ref(true),
      datalist: '', // 地址list
      dialogVisible: false, // 新增收货地址弹窗开关
      dialogAmend: false, // 修改收货地址弹窗开关
      name: '', // 新增收货人name
      phone: '', // 手机号
      selectedOptions: [], // 地区信息
      detailaddress: '', // 详细地址
      addressmessage: '',
      setDefaults: 1,
      changeaddressmes: '' // 修改存储数据
    }
  },
  components: {},
  methods: {
    aaaaa () {
      console.log(this.changeaddressmes.defaultStatus)
    },
    sub () { // 新增保存
      if (!this.name) {
        this.$message({
          message: '请填写姓名',
          type: 'error'
        })
        return
      }
      const reg = 11 && /^((13|14|15|17|18|16|19|11|12)[0-9]{1}\d{8})$/
      if (this.phone.length < 1) {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      } else if (!reg.test(this.phone)) {
        this.$message({
          message: '手机格式不正确',
          type: 'error'
        })
        return
      }
      if (!this.detailaddress) {
        this.$message({
          message: '请填写详细地址',
          type: 'error'
        })
        return
      }

      this.showallgg ? (this.setDefaults = 1) : (this.setDefaults = 0)
      const usermessage = getStore('usermessage')
      const uid = JSON.parse(usermessage).id
      this.dialogVisible = false
      const loading = this.$loading({
        lock: true,
        text: '提交中'
      })
      const data = {
        // city: this.city,
        detailAddress: this.detailaddress,
        memberId: uid,
        name: this.name,
        phoneNumber: this.phone,
        // province: this.province,
        // region: this.area,
        defaultStatus: this.setDefaults
        // selectedOptions: JSON.stringify(this.selectedOptions)
      }
      // console.log(data)
      try {
        http.post('mall-portal/member/address/add', data, true)
          .then(res => {
            console.log(res)
            loading.close()
            this.addressmessage = res.data.data
            this.$store.state.address = res.data.data
            ElMessage.success({
              message: '添加成功'
            })
            this.name = ''
            this.phone = ''
            this.detailaddress = ''
            this.selectedOptions = []
            this.getclosedlists()
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        loading.close()
        // TODO handle the exception
      }
    },
    getclosedlists () { // 获取所有地址信息
      http.get('mall-portal/member/address/list')
        .then(res => {
          console.log(res)
          this.datalist = res.data.data
          if (this.datalist.length > 0) {
            // this.isact(this.datalist[0], 0)
          }
        }).catch(err => {
          console.log(err)
        })
    },
    changeaddress (item) { // 修改信息填入弹框
      console.log(item)
      // console.log(typeof item.selectedOptions)
      item.defaultStatus === 1
        ? (item.defaultStatus = true)
        : (item.defaultStatus = false)
      if (typeof item.selectedOptions === 'string') {
        item.selectedOptions = JSON.parse(item.selectedOptions)
      }
      this.changeaddressmes = item
    },
    async modifyaddress () { // 修改内容保存确定
      if (!this.changeaddressmes.name) {
        this.$message({
          message: '请填写收货人姓名',
          type: 'error'
        })
        return
      }
      if (!this.changeaddressmes.phoneNumber) {
        this.$message({
          message: '请填写姓名',
          type: 'error'
        })
        return
      }
      const reg = 11 && /^((13|14|15|17|18|16|19|11|12)[0-9]{1}\d{8})$/
      if (this.changeaddressmes.phoneNumber.length < 1) {
        this.$message({
          message: '手机号不能为空',
          type: 'error'
        })
        return
      } else if (!reg.test(this.changeaddressmes.phoneNumber)) {
        this.$message({
          message: '手机格式不正确',
          type: 'error'
        })
        return
      }
      if (!this.changeaddressmes.detailAddress) {
        this.$message({
          message: '请填写详细地址',
          type: 'error'
        })
      }

      const usermessage = getStore('usermessage')
      const uid = JSON.parse(usermessage).id
      let type = 0
      this.changeaddressmes.defaultStatus ? (type = 1) : (type = 0)

      const data = {
        detailAddress: this.changeaddressmes.detailAddress,
        memberId: uid,
        name: this.changeaddressmes.name,
        phoneNumber: this.changeaddressmes.phoneNumber,
        defaultStatus: type
      }
      console.log(data)
      try {
        http.post(`/mall-portal/member/address/update/${this.changeaddressmes.id}`, data, true)
          .then(res => {
            console.log(res)
            this.addressmessage = res.data.data
            ElMessage.success({
              message: '修改成功!'
            })
            this.dialogAmend = false
            // this.$store.state.address = res.data.data
            this.getclosedlists()
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        // TODO handle the exception
      }
    },
    // 删除地址
    deleteaddress (id, index) {
      this.$confirm('您确定删除此地址吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirmdelete(id, index)
        })
        .catch(() => {})
    },
    async confirmdelete (id, index) {
      console.log(id)
      console.log(index)
      try {
        http.post(`mall-portal/member/address/delete/${id}`, {})
          .then(res => {
            console.log(res)
            if (res.data.flag) {
              this.datalist.splice(index, 1)
              // if (id === this.$store.state.address.id) {
              //   console.log('id一样')
              //   this.addressmessage = this.datalist[0] || ''
              //   this.$store.state.address = this.datalist[0] || ''
              // }
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              return ''
            }
            // this.datalist.splice(index, 1)
            // console.log(this.$store.state.address)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        // TODO handle the exception
      }
    }
  },
  mounted () {
    this.getclosedlists()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>
@import '../../style/viriables.scss';
.cont{
  font-family: Microsoft YaHei;
  h2{
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    color: $title;
  }
  .body{
    .addspan{
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #ebebeb;
      color: $title;
      cursor: pointer;
    }
    .body_ul{
      display: flex;
      // flex-flow: column;
      flex-wrap: wrap;
      .body_li{
        width: 50%;
        padding: 20px;
        box-sizing: border-box;
        .el-card{
          margin: 0 auto;
          width: 400px;
          min-height: 210px;
          .el-card__body{
            div{
              // display: flex;
              // flex-flow: column;
              .isact{
                // display: inline-block;
                min-width: 60px;
                padding: 0 5px;
                height: 30px;
                display: block;
                float: right;
                background: $title;
                color: #ebebeb;
                border-radius: 10px;
                line-height: 30px;
                text-align: center;
              }
              .bottom{
                // margin-top: 20px;
                display: flex;
                flex-flow: column;
                .msg{
                  line-height: 40px;
                }
              }
            }
            .bottom_btn{
              margin-top: 20px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              .el-button{
                width: 90px;
              }
              .remove{
                background: $title;
                color: #ebebeb;
              }
            }
          }
        }
      }
    }
  }
}
.el-overlay{
  .el-overlay-dialog{
    .el-dialog{
      .el-dialog__header{
        .el-dialog__title{
          width: 100%;
          display: inline-block;
          text-align: center;
        }
      }
      .el-dialog__body{
        .addsite{
          .add-item{
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px 0;
            .add-item-left {
              min-width: 90px;
              text-align: left;
              color: #595757;
              font-size: 14px;
            }
            .add-item-right {
              flex: 1;
            }
          }
          .footbtn{
            width: 100%;
            display: flex;
            .save{
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>
