<template>
<div class='main'>
  <div class="security_top">
    <h2>{{"仓储管理"}}</h2>
  </div>
  <div class="security_body">
    <!-- <logisticsMentVue/> -->
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="物流管理"><logisticsMentVue/></el-tab-pane>
      <el-tab-pane label="仓储信息"><wareinforMationVue/></el-tab-pane>
    </el-tabs>
  </div>
</div>
</template>

<script>
import { ref } from 'vue'
import logisticsMentVue from './logisticsMent.vue'
import wareinforMationVue from './wareinforMation.vue'

export default {
  name: 'warehouse',
  data () {
    return {
      activeName: ref('')
    }
  },
  components: { logisticsMentVue, wareinforMationVue },
  methods: {
  },
  mounted () {

  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
@import '../../../style/viriables.scss';
.main{
  // font-family: Microsoft YaHei;
  padding: 20px;
  .security_top{
    h2{
      height: 50px;
    }
  }
}
</style>
