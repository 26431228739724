<template>
<div class='band_bank'>
  <div class='cont'>
  <h2>{{$t('m.personalcenter_myorder.Card_management')}}</h2>
  <div class="body">
    <span class="addspan" @click="adddialogVisible = true">{{$t('m.personalcenter_myorder.Add_bank_card')}}</span>
    <div class="flexul">
      <ul class="body_ul">
      <li class="body_li"
       v-for="(i,k) in bankList" :key="k">
        <el-card :body-style="{ background: i.auditStatus == 3? '': i.auditStatus == 2 ? '#c1c1c1' : '#e8eaed'}">
          <div v-if="i.auditStatus == 0">
            <span class="noact">{{$t('m.personalcenter_myorder.in_review')}}</span>
            <span class="isact"><icon-svg :icon-class="tobankIcon(i.bankName)" /></span>
            <div class="bottom">
              <p class="msg"><span>{{i.bankName}}</span></p>
              <p class="msg"><span>{{i.bankCardNo}}</span></p>
            </div>
          </div>
          <div v-if="i.auditStatus == 1">
            <span class="noact">{{$t('m.personalcenter_myorder.To_be_backfilled')}}</span>
            <span class="isact"><icon-svg :icon-class="tobankIcon(i.bankName)" /></span>
            <div class="bottom">
              <p class="msg"><span>{{i.bankName}}</span></p>
              <p class="msg"><span>{{i.bankCardNo}}</span></p>
            </div>
          </div>
          <div v-if="i.auditStatus == 2 || i.auditStatus == 4" :class="i.auditStatus == 2?'nopass':''">
            <!-- <span class="noact">审核未通过</span> -->
            <el-popover
              placement="top-start"
              :title="i.auditStatus == 2?'驳回原因：':'作废原因：'"
              trigger="hover"
              :content="i.auditContent"
            >
              <template #reference>
                <span class="noact">{{i.auditStatus == 2?'审核未通过':'到期未回填'}}</span>
              </template>
            </el-popover>
            <span class="isact"><icon-svg :icon-class="tobankIcon(i.bankName)" /></span>
            <div class="bottom">
              <p class="msg"><span>{{i.bankName}}</span></p>
              <p class="msg"><span>{{i.bankCardNo}}</span></p>
            </div>
          </div>
          <div v-if="i.auditStatus == 3">
            <span class="isact"><icon-svg :icon-class="tobankIcon(i.bankName)" /></span>
            <div class="bottom">
              <p class="msg"><span>{{i.bankName}}</span></p>
              <p class="msg"><span>{{i.bankCardNo}}</span></p>
            </div>
          </div>
          <div class="bottom_btn">
            <el-button v-if="i.auditStatus == 3 || i.auditStatus == 0" size="small" round class="remove"
            @click.stop="jbBank(i, k)">{{i.auditStatus == 3?$t('m.personalcenter_myorder.remove_binding'):$t('m.personalcenter_myorder.unbind')}}</el-button>
            <el-button v-if="i.auditStatus == 1" size="small" round
            @click.stop="htBank(i, k)">{{$t('m.personalcenter_myorder.backfill')}}</el-button>
            <el-button v-if="i.auditStatus == 2 || i.auditStatus == 4" size="small" round class="remove"
            @click.stop="jbBank(i, k)">{{$t('m.personalcenter_myorder.delete')}}</el-button>
          </div>
        </el-card>
      </li>
      <div class="nodata" v-if="bankList.length == 0">
        <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
      </div>
    </ul>
    </div>
  </div>
</div>
     <!-- 新增银行卡 -->
    <el-dialog
      v-model="adddialogVisible"
      :destroy-on-close="true"
      width="40%"
      :title="$t('m.personalcenter_myorder.Bind_a_bank_card')"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="dialogcont">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Bank_card_type')}}：</div>
            <div class="zyitem-right">
              <!-- <el-radio v-model="accountType" label="个人">个人绑卡</el-radio> -->
              <el-radio v-model="accountType" label="企业">{{$t('m.personalcenter_myorder.Enterprise_card_binding')}}</el-radio>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Supervisory_accounts')}}：</div>
            <div class="zyitem-right">
              富友支付
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Supervisory_account')}}：</div>
            <div class="zyitem-right">
              <el-input
                disabled
                v-model="capitalAccount"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.fund_account')}}：</div>
            <div class="zyitem-right">
              <el-input disabled
                v-model="userAccount"
              ></el-input>
            </div>
          </div>
          <div class="zyitem" v-if="accountType==='企业'">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Opening_city')}}：</div>
            <div class="zyitem-right">
              <el-cascader
              v-model="bankCityValue"
              :props="propsList"
              :options="bankCityOneList"
              :show-all-levels="false"
              @change="handleChange"/>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{accountType=='个人'?'会员姓名：':`${$t('m.personalcenter_myorder.Enterprise_Name')}：`}}</div>
            <div class="zyitem-right">
              <el-input
                v-model="name"
                maxlength="15"
                :placeholder="accountType=='个人'?'请输入姓名':$t('m.personalcenter_myorder.Please_enter_enterprise_name')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.ID_type')}}：</div>
            <div class="zyitem-right">
              <el-select v-model="typeOfCertificate">
                <el-option
                  v-for="item in provelist"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.BANK_ID_Number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="idNumber"
                maxlength="20"
                :placeholder="$t('m.personalcenter_myorder.Please_enter_ID_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.phone_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="phone" maxlength="11"
                show-word-limit
                :placeholder="$t('m.personalcenter_myorder.Please_enter_mobile_phone_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Bank_card_number')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="bankCardNo" maxlength="30"
                show-word-limit
                :placeholder="$t('m.personalcenter_myorder.Please_enter_bank_card_number')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Name_of_Account_Bank')}}：</div>
            <div class="zyitem-right">
              <el-input
                v-model="openBankName" maxlength="30"
                :placeholder="$t('m.personalcenter_myorder.Please_enter_Account_Bank')"
              ></el-input>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.bank_name')}}：</div>
            <div class="zyitem-right">
              <!-- <el-input
                v-model="bankName" maxlength="15"
                placeholder="请输入银行名称"
              ></el-input> -->
              <el-select v-model="bankName"
              filterable
              remote
              value-key="id"
              :remote-method="remoteMethod"
              :loading="bankNameLoading"
              @change="bcode">
                <el-option
                  v-for="item in bankNameList"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </div>
          </div>

        </div>
        <el-button @click="addptsub" class="save">
          {{$t('m.personalcenter_myorder.Commit_binding')}}
        </el-button>
      </div>
    </el-dialog>
     <!-- 删除/解绑 银行卡 -->
    <el-dialog
      v-model="removedialogVisible"
      :destroy-on-close="true"
      width="40%"
      title="解绑银行卡"
      :close-on-click-modal="false"
    >
      <div class="addfps">
        <div class="dialogcont">
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Bank_card_type')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.registrationType}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Supervisory_account')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.customerAccount}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{jbankData.registrationType=='个人'?'会员姓名：':`${$t('m.personalcenter_myorder.Enterprise_Name')}：`}}</div>
            <div class="zyitem-right">
              <p>{{jbankData.name}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.ID_type')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.typeOfCertificate}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.BANK_ID_Number')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.idNumber}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.phone_number')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.phone}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Bank_card_number')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.bankCardNo}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.Name_of_Account_Bank')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.openBankName}}</p>
            </div>
          </div>
          <div class="zyitem">
            <div class="zyitem-left">{{$t('m.personalcenter_myorder.bank_name')}}：</div>
            <div class="zyitem-right">
              <p>{{jbankData.bankName}}</p>
            </div>
          </div>
        </div>
        <el-button @click="deleteBankCard" class="save">{{$t('m.personalcenter_myorder.Commit_to_unbind')}}</el-button>
      </div>
    </el-dialog>
    <!-- 回填弹窗 -->
    <el-dialog
     v-model="htdialogVisible"
      :destroy-on-close="true"
      width="30%"
      :title="$t('m.personalcenter_myorder.Backfill_amount')"
      :close-on-click-modal="false">
      <div class="htdiv">
        <el-input v-model="htnum" />
        <el-button @click="htSubmit()" size="small">{{$t('m.personalcenter_myorder.confirm')}}</el-button>
      </div>
    </el-dialog>
</div>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from 'vue-demi'
import http from '../../api/http'
import { getStore } from '../../tools/storage'
import { ElMessage } from 'element-plus'

// 查询银行卡列表
const queryBankCardByCapitalAccount = (data) => {
  return http.get('mall-finance/finance/capital/queryBankCardByCapitalAccount', data)
}
// 添加绑卡
const bankCardBoundToTheUser = (data) => {
  return http.post('mall-finance/finance/capital/bankCardBoundToTheUser', data, true)
}
// 解绑银行卡
const deleteBC = (data) => {
  return http.post(`mall-finance/finance/capital/delete/${data}`)
}
// 查银行
const selectBankList = (data) => {
  return http.get('mall-finance/bankCode/selectList', data)
}
// 查一级城市
const getParent = () => {
  return http.get('mall-finance/bankCity/getParent')
}
// 查二级银行
const getCityByParentCode = (parentCode) => {
  return http.get(`mall-finance/bankCity/getCityByParentCode/${parentCode}`)
}

// 用户回填
const userBackfillMoney = (data) => {
  return http.post('mall-finance/finance/capital/userBackfillMoney', data)
}
export default {
  name: '',
  data () {
    return {
      capitalAccount: '', // 当前用户的资金信息
      adddialogVisible: ref(false),
      removedialogVisible: ref(false),
      bankList: ref([]),

      htdialogVisible: ref(false),
      htData: null,
      htIndex: null,
      htnum: 0,

      accountType: '企业',
      registrationType: '', // 注册类型
      userAccount: '', // 资金账户
      provelist: ['身份证', '港澳台居民通行证', '中国护照', '统一社会信用代码', '组织机构代码证'],
      typeOfCertificate: '', // 证件类型
      name: '', // 名称
      idNumber: '', // 身份证
      phone: '',
      bankCardNo: '', // 银行卡号
      openBankName: '', // 开户行
      bankName: '', // 银行名称
      bankNameCode: '', // 银行名称代码
      bankNameList: ref([]),
      bankNameLoading: ref(false),
      // rules:{
      //   registrationType:{rule:/\S/,msg:"请选择注册类型"},
      //   typeOfCertificate:{rule:/\S/,msg:"请选择证件类型"},
      //   name:{rule:/\S/,msg:"请输入姓名"},
      //   idNumber:{rule:/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,msg:"请输入正确身份证号"},
      //   phone:{rule:/^1[345789]\d{9}$/,msg:"请输入正确手机号"},
      //   bankCardNo:{rule:/^([1-9]{1})(\d{15}|\d{16}|\d{18})$/,msg:"请输入正确的银行卡号"},
      //   openBankName:{rule:/\S/,msg:"请输入银行开户行名称"},
      //   bankName:{rule:/\S/,msg:"请输入银行名称"}
      // },

      jbankData: {},
      jbIndex: '',

      bankCityOneList: ref([]), // 一级城市list
      bankCityValue: '' // 选中的数据
    }
  },
  components: {},
  methods: {
    async initData () { // 初始化银行卡list
      const data = {
        capitalAccount: this.capitalAccount
      }
      const res = await queryBankCardByCapitalAccount(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.bankList = res.data.data
      }
    },
    tobankIcon (data) { // 显示图标
      return data.indexOf('工商') !== -1 ? 'yinhangbiaozhi_gongshangyinhang'
        : data.indexOf('建设') !== -1 ? 'jiansheyinhang1'
          : data.indexOf('农业') !== -1 ? 'nongyeyinhang'
            : data.indexOf('中国银行') !== -1 ? 'yinhang-zhongguoyinhang'
              : data.indexOf('邮政') !== -1 ? 'yinhang-youzhengchuxu'
                : data.indexOf('交通') !== -1 ? 'zhongguojiaotongyinhang'
                  : data.indexOf('招商') !== -1 ? 'zhaoshangyinhang1'
                    : data.indexOf('浦发') !== -1 ? 'pufayinhang'
                      : data.indexOf('广发') !== -1 ? 'pufayinhang'
                        : data.indexOf('光大') !== -1 ? 'guangdayinhang'
                          : data.indexOf('民生') !== -1 ? 'minshengyinxing'
                            : data.indexOf('中信') !== -1 ? 'zhongxinyinhangbank1193438easyiconnet'
                              : data.indexOf('华夏') !== -1 ? 'changyonglogo17'
                                : data.indexOf('兴业') !== -1 ? 'xingyeyinhang'
                                  : data.indexOf('上海银行') !== -1 ? 'shanghaiyinxing'
                                    : data.indexOf('北京银行') !== -1 ? 'beijingyinxing'
                                      : 'yinlian'
    },
    async addptsub () { // 绑卡确定
      // let data = {}
      if (this.name === '') {
        ElMessage.warning({ message: '请输入正确的名称！' })
        this.name = ''
        return
      }
      if (this.typeOfCertificate === '') {
        ElMessage.warning({ message: '请选择证件类型！' })
        this.typeOfCertificate = ''
        return
      }
      if (this.idNumber === '') {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.typeOfCertificate === '身份证' && !/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.idNumber)) {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.typeOfCertificate === '港澳台居民通行证' && !/^8[123]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/.test(this.idNumber)) {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.typeOfCertificate === '中国护照' && !/^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g|E|e]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/.test(this.idNumber)) {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.typeOfCertificate === '统一社会信用代码' && !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/.test(this.idNumber)) {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.typeOfCertificate === '组织机构代码证' && !/[a-zA-Z0-9]{8}-[a-zA-Z0-9]/.test(this.idNumber)) {
        ElMessage.warning({ message: '请输入正确的证件号码！' })
        this.idNumber = ''
        return
      }
      if (this.phone === '' || !/^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(this.phone)) {
        ElMessage.warning({ message: '请输入正确的手机号码！' })
        this.phone = ''
        return
      }
      if (this.bankCardNo === '' || !/^[1-9]\d{9,29}$/.test(this.bankCardNo)) {
        ElMessage.warning({ message: '请输入正确的银行卡号！' })
        this.bankCardNo = ''
        return
      }
      if (this.accountType === '企业' && this.bankCityValue === '') {
        ElMessage.warning({ message: '请选择正确的城市！' })
        this.bankCityValue = ''
        return
      }
      if (this.openBankName === '') {
        ElMessage.warning({ message: '请输入开户行！' })
        this.openBankName = ''
        return
      }
      if (this.bankNameCode === '') {
        ElMessage.warning({ message: '请选择银行！' })
        this.bankNameCode = ''
        return
      }
      const data = {
        capitalAccount: this.capitalAccount,
        registrationType: this.accountType,
        userAccount: this.userAccount,
        typeOfCertificate: this.typeOfCertificate,
        name: this.name,
        idNumber: this.idNumber,
        phone: this.phone,
        bankCardNo: this.bankCardNo,
        openBankName: this.openBankName,
        bankName: this.bankName.name,
        bankNameCode: this.bankNameCode,
        cityCode: this.accountType === '企业' ? this.bankCityValue[1] : null
      }
      const res = await bankCardBoundToTheUser(data)
      console.log(res)
      if (res.data.code === 200) {
        ElMessage.success({
          message: res.data.msg
        })
        this.adddialogVisible = false
        this.name = ''
        this.typeOfCertificate = ''
        this.idNumber = ''
        this.phone = ''
        this.bankCardNo = ''
        this.openBankName = ''
        this.bankName = ''
        this.initData()
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    jbBank (item, index) { // 解绑弹窗数据
      this.jbankData = item
      this.jbIndex = index
      console.log(this.jbankData)
      this.removedialogVisible = true
    },
    async deleteBankCard () { // 解绑确定
      this.$confirm('您确定解绑此银行卡吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const res = deleteBC(this.jbankData.id)
        res.then(succ => {
          if (succ.data.code === 200) {
            ElMessage.success({ message: succ.data.msg })
            this.removedialogVisible = false
            this.bankList.splice(this.jbIndex, 1)
            this.jbIndex = ''
          }
        }).catch(err => {
          console.log(err)
        })
      })
        .catch(() => {})
    },
    htBank (item, index) { // 回填弹窗
      this.htData = item
      this.htIndex = index
      this.htdialogVisible = true
    },
    async htSubmit () {
      // console.log(this.htnum)
      // console.log(this.htData)
      // console.log(this.htIndex)
      const data = {
        backfillMoney: this.htnum,
        id: this.htData.id
      }
      const res = await userBackfillMoney(data)
      console.log(res)
      if (res.data.code === 200) {
        this.bankList[this.htIndex].auditStatus = 3
        this.htdialogVisible = false
        this.htnum = 0
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    async selectBank () {
      const res = await selectBankList()
      // console.log(res)
      if (res.data.code === 200) {
        this.bankNameList = res.data.data
      }
    },
    async bankCityList () { // 查银行城市一级
      const res = await getParent()
      console.log(res)
      // console.log(this.bankCityOneList)
      if (res.data.code === 200) {
        res.data.data.forEach(item => {
          // console.log(bankCityOneList)
          this.bankCityOneList.push({
            label: item.parentName,
            value: item.parentCode,
            children: []
          })
        })
        // console.log(arr)
      }
    },
    handleChange (e) {
      // console.log(e)
      console.log(this.bankCityValue)
    },
    bcode (e) {
      console.log(e)
      this.bankNameCode = e.code
    },
    async remoteMethod (e) {
      this.bankNameLoading = true
      const data = {
        name: e
      }
      const res = await selectBankList(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.bankNameList = res.data.data
        this.bankNameLoading = false
      }
    }
    // async testcity () {
    //   console.log('测试数据***********************************************')
    //   let demo = ''
    //   var father = []
    //   // var son = []
    //   const res = await getParent()
    //   if (res.data.code === 200) {
    //     demo = res.data.data
    //     console.log(demo)
    //     demo.forEach(item => {
    //       father.push(item.parentName)
    //       // console.log(bankCityOneList)
    //       getCityByParentCode(item.parentCode).then(res => {
    //         console.log(res)
    //         if (res.data.code === 200) {
    //           // item.children = res.data.data
    //           this.son.push(res.data.data)
    //         }
    //       })
    //     })
    //   }
    //   console.log(father)
    //   console.log(this.son)
    // }
  },
  mounted () {
    this.capitalAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).capitalAccount : null
    this.userAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null
    this.initData()
    this.selectBank()
    this.bankCityList()

    // this.testcity()
  },
  setup (props, ctx) {
    // let id = 0
    // var bankCityOneList = ref([])
    const datacop = getCurrentInstance()
    const propsList = {
      lazy: true,
      lazyLoad (node, resolve) {
        // const { level } = node
        const parentCode = node.value
        console.log(node)
        const uid = node.uid

        getCityByParentCode(parentCode).then(res => {
          if (res.data.code === 200) {
            let arr = []
            res.data.data.map((item) => {
              // console.log(item)
              arr.push({
                label: item.cityName,
                value: item.cityCode,
                leaf: node.level >= 1
              })
            })
            datacop.data.bankCityValue = []
            resolve(arr)
            // setTimeout(() => {
            //   resolve(arr)
            // }, 500)
            datacop.data.bankCityOneList[uid].children = arr
            // console.log(res)
            arr = []
            // return arr
          } else {
            ElMessage.error({
              message: res.data.msg
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
    onMounted(() => {
      // bankCityList()
    })
    return {
      propsList
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
@import '../../style/iconfont.css';
@import '../../style/viriables.scss';
.band_bank{
  .cont{
    font-family: Microsoft YaHei;
    h2{
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      color: $title;
    }
    .body{
      .addspan{
        display: block;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #ebebeb;
        color: $title;
        cursor: pointer;
      }
      .flexul{
        .body_ul{
          display: flex;
          flex-wrap: wrap;
          .body_li{
            flex-shrink: 0;
            float: left;
            width: 50%;
            padding: 20px;
            box-sizing: border-box;
            .el-card{
              margin: 0 auto;
              width: 400px;
              // min-height: 200px;
              .el-card__body{
                div{
                  // display: flex;
                  // flex-flow: column;
                  position: relative;
                  .isact{
                    display: inline-block;
                    // width: 60px;
                    // height: 30px;
                    display: block;
                    font-size: 50px;
                    // border-radius: 10px;
                    line-height: 30px;
                    text-align: center;
                    position: absolute;
                  }
                  .noact{
                    display: inline-block;
                    position: absolute;
                    height: 25px;
                    min-width: 90px;
                    line-height: 25px;
                    font-size: 14px;
                    background: #c6824e;
                    text-align: center;
                    color: #ffffff;
                    border-radius:5px 3px 3px 10px;
                    right: 0;
                    z-index: 10;
                  }
                  .bottom{
                    // margin-top: 20px;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    text-align: center;
                    .msg{
                      line-height: 40px;
                    }
                  }
                }
                .nopass{
                  // color: #999;
                }
                .bottom_btn{
                  margin-top: 20px;
                  display: flex;
                  justify-content: space-around;
                  align-items: center;
                  .el-button{
                    min-width: 90px;
                  }
                  .remove{
                    background: $title;
                    color: #ebebeb;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__header{
          .el-dialog__title{
            width: 100%;
            display: inline-block;
            text-align: center;
          }
        }
        .el-dialog__body{
          font-size: 18px;
          .addfps{
            padding:0 40px;
            line-height: 50px;
            .dialogcont{
              .zyitem{
                display: flex;
                align-items: center;
                .zyitem-left{
                  min-width: 100px;
                  display: inline-block;
                  text-align-last: justify;
                }
                .zyitem-right{
                  flex: 1;
                }
              }
            }
          }
          .addsite{
            .add-item{
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 10px 0;
              .add-item-left {
                width: 90px;
                text-align: left;
                color: #595757;
                font-size: 14px;
              }
              .add-item-right {
                flex: 1;
              }
            }
            .footbtn{
              width: 100%;
              display: flex;
              .save{
                margin: 0 auto;
              }
            }
          }
          .htdiv{
            display: flex;
            flex-flow: column;
            .el-button{
              margin-top: 20px;
              width: 100px;
            }
          }
        }
      }
    }
  }
}
</style>
