<template>
<!-- 关注店铺 -->
<div class='attention'>
  <!-- <div class="title">
    <el-input v-model="inputValue" placeholder="企业名称">
      <template #append>
        <span>搜索</span>
      </template>
    </el-input>
  </div> -->
  <div class="att_body" v-loading="loading" element-loading-text="正在查询...">
    <ul class="cont_ul">
      <li class="cont_li" v-for="(i,k) in attDataList" :key="k">
        <img class="icon" :src="i.icon" alt="">
        <div class="profile">
          <p class="name">{{i.nickName}}</p>
          <!-- <span class="to_kefu">联系客服</span> -->
          <p class="isbuy">购买过的店铺</p>
        </div>
        <div class="list_img">
          <img v-for="(j,jk) in i.followTheStoreProductVos.slice(0,5)" :key="jk" :src="j.pic">
        </div>
      </li>
      <div class="nodata" v-if="attDataList.length == 0">
        <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
      </div>
    </ul>
  </div>
  <div class="showfooter">
    <el-pagination
      v-model:currentPage="currentPage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalnum"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
// 查询关注店铺
const queryPmsFollowTheStoreByPage = (data) => {
  return http.get('/mall-portal/mall/pmsfollowthestore/queryPmsFollowTheStoreByPage', data)
}
export default {
  name: 'storeAtten',
  data () {
    return {
      // 存放总数据个数
      totalnum: ref(10),
      // 存放一页显示个数
      pagesize: ref(5),
      // 默认展示页
      currentPage: ref(1),
      loading: ref(true),
      inputValue: ref(''), // 输入框数据
      attDataList: ref([]), // 关注数据
      imgList: ref(['', '', '', '', ''])

    }
  },
  components: {},
  methods: {
    handleCurrentChange (e) {
      // console.log(e)
      this.loading = true
      this.currentPage = e
      this.initDataList()
    },
    async initDataList () {
      const data = {
        pageNum: this.currentPage,
        pageSize: 5
      }
      const res = await queryPmsFollowTheStoreByPage(data)
      console.log(res)
      if (res.data.code === 200) {
        this.attDataList = res.data.data.list
        this.totalnum = res.data.data.total
        this.loading = false
      }
    }
  },
  mounted () {
    this.initDataList()
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>
<style lang="scss">
.attention{
  .title{
    .el-input{
      // width: 50%;
      .el-input-group__append{
        background: #C6824E;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.attention{
  font-family: Microsoft YaHei;
  font-weight: 400;
  .title{
    padding:10px 20px;
    text-align: end;
    border-top: 3px solid #e5e5e5;
    border-bottom: 3px solid #e5e5e5;
    .el-input{
      width: 50%;
    }
  }
  .att_body{
    min-height: 685px;
    .cont_ul{
      padding: 10px;
      display: flex;
      flex-flow: wrap;
      .cont_li{
        flex: 1;
        display: flex;
        align-items: center;
        padding: 5px;
        // box-sizing: box;
        border-bottom: 1px solid #E5E5E5;
        .icon{
          width: 102px;
          // height: 102px;
        }
        .profile{
          width: 259px;
          box-sizing: border-box;
          padding: 10px 20px;
          display: block;
          .name{
            line-height: 39px;
            font-size: 16px;
            color: #333333;
          }
          .to_kefu{
            display: block;
            text-align: center;
            line-height: 21px;
            width: 60px;
            height: 21px;
            background: #FFFFFF;
            border: 1px solid #C9C9C9;
            border-radius: 2px;
            font-size: 12px;
            color: #666666;
          }
          .isbuy{
            line-height: 39px;
            font-size: 12px;
            color: #999999;
          }
        }
        .list_img{
          flex: 1;
          display: flex;
          img{
            // flex: 1;
            margin: 0 10px;
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
</style>
