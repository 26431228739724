<template>
  <div class="showorder">
    <span class="tittle">我的转售</span>
    <div class="bodystatus">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
        v-for="(i,k) in panList" :key="k"
        :label="i.label"
        :name="i.name"
        >
          <component :is="activeName"
          @olistData="()=>{orderListShow}">
          </component>
        </el-tab-pane>
      </el-tabs>
      <div class="div_orderlist">
        <div class="alltitle">
          <ul class="all_title_ul">
            <li class="all_title_li"
            v-for="i in alltitle" :key="i">
              {{i}}
            </li>
          </ul>
        </div>
        <div class="nodata" v-if="ListData.length == 0">
          <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E8%AE%A2%E5%8D%95%E4%B8%BA%E7%A9%BA.png" alt="">
        </div>
        <div class="order_div"
          v-for="(i,k) in ListData" :key="k">
          <div class="order_title">
            <p class="order_title_p">
              <!-- 订单号：{{activeName=='InResale'?i.resaleSn:i.resaleOrderSn}} -->
              订单号：{{i.orderSn}}
            </p>
            <span class="order_title_span">
              {{i.createTime}}
              <!-- <el-tooltip placement="right"
                effect="light"
                v-if="activeName!=='InResale'"
              >
                <template #content>
                   <br>
                </template>
                <button class="order_title_button">联系卖家</button>
              </el-tooltip> -->
            </span>
          </div>

          <div class="order_body">
            <ul class="order_body_ul">
              <li class="order_body_li body_num1">
                <div class="namebody">
                  <img :src="activeName=='InResale'?
                  i.productPic:i.omsOrder.omsOrderItem.productPic" alt="">
                  <div class="name_time">
                    <p>{{activeName=='InResale'?
                      i.productName:i.omsOrder.omsOrderItem.productName}}</p>
                    <span>{{'规格1'}}</span>
                  </div>
                </div>
              </li>
              <li class="order_body_li body_num2">
                <span class="li2_span_kg">{{activeName=='InResale'?i.stock:i.num}}</span>
              </li>
              <li class="order_body_li body_num3">
                <p class="name_phone_p">{{activeName=='InResale'?
                  i.takeProductTime:i.omsOrder.takeGoodsTime}}</p>
              </li>
              <li class="order_body_li body_num4">
                <span class="money_span">
                  {{activeName=='InResale'?i.resalePrice:i.omsOrder.totalAmount}}</span>
              </li>
              <li class="order_body_li body_num5">
                <span class="status_name" v-if="false"> {{
                          i.status == 0
                            ? "待付款"
                            : i.status == 1
                            ? "已付款"
                            : i.status == 5
                            ? "待发货"
                            : i.status == 2
                            ? "卖家待备货"
                            : i.status == 3
                            ? "待确认备货"
                            : i.status == 12
                            ? "待付尾款"
                            : i.status == 8
                            ? "待确认发票"
                            : i.status == 4
                            ? "拒绝确认备货"
                            : i.status == 6
                            ? "待收货"
                            : i.status == 7
                            ? "已收货"
                            : i.status == 9
                            ? "已完成"
                            : i.status == 10
                            ? "已取消"
                            : i.status == 11
                            ? "订单失败"
                            : i.status == 15
                            ? "预订成功"
                            : i.status == 16
                            ? "预订失败"
                            : i.status == 17
                            ? "待确认"
                            : i.status == 30
                            ? "待付款-预提"
                            : i.status == 31
                            ? "待付款-待预提"
                            : i.status == 32
                            ? "已付款-待预提"
                            : i.status == 33
                            ? "卖家待确认预提"
                            : i.status == 34
                            ? "待确认预提"
                            : i.status == 40
                            ? "转售中"
                            : ""
                        }}</span>
              </li>
              <li class="order_body_li body_num6">
                <button class="order_paymoney" v-if="activeName=='HasResale'"
                  @click="sumitbtn(activeName,i,k)"
                  :class="i.status!==3?'disabled_btn':''"
                  :disabled="i.status!==3"
                >开发票</button>
                <el-popconfirm v-if="activeName=='InResale'"
                  confirm-button-text="是的"
                  cancel-button-text="考虑一下"
                  icon-color="red"
                  @confirm="confirmEvent"
                  @cancel="cancelEvent"
                  title="您确定要下架该商品?"
                >
                  <template #reference>
                    <button class="order_paymoney"
                      @click="sumitbtn(activeName,i,k)"
                    >下架</button>
                  </template>
                </el-popconfirm>
              </li>
            </ul>
          </div>

          <div class="order_foot" v-if="i.status==30||i.status==31||i.status==32||i.status==33||i.status==34?true:false">
            <p class="order_foot_p">
              {{i.presellTimeScope.split(',')[0]}}
            </p>
          </div>

        </div>
      </div>
    </div>
    <!-- 发票弹窗 -->
    <div class="dialog_div">
      <el-dialog v-model="ConfirmInvoiceDialog" width="30%"
        destroy-on-close
        title="确认发票">
        <div class="namebody">
          <el-button :loading="sendInvioceBtn"
          @click="ConfirmInvoiceBtn">确认发票</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import emitter from '../../tools/bus'
import { reactive, ref } from 'vue-demi'
import http from '../../api/http.js'
import { ElMessage } from 'element-plus'

export default {
  name: 'myOrder',
  data () {
    return {
      panList: [
        { label: '已转售', name: 'HasResale' },
        { label: '转售中', name: 'InResale' }
      ], // 订单状态标题内容
      alltitle: ['商品信息', '数量', '提货时间', '金额', '', '操作'],
      activeName: 'InResale', // 当前选择的订单项
      // 存储各个种类订单数量
      // 0 已转售 1 转售中
      labelStatus: [0, 0],
      ListData: reactive([]), // 存要展示的转售数据
      orderDataS: reactive(), // 初始化显示全部订单
      totalnum: ref(), // 存放总数据个数
      pagesize: ref(10), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页
      ConfirmInvoiceDialog: ref(false),
      sendInvioceBtn: ref(false), // 发票按钮
      sendInviocedata: '', // 发票数据
      indexData: '', // 数据第几条
      soldOut: '' // 下架商品信息
    }
  },
  components: {
  },
  methods: {
    // 初始化数量
    initdata () {
      console.log('转售页面初始化==')
    },
    // 点击切换的函数
    handleClick (tab, event) {
      // console.log(tab.props)
      this.orderListShow(tab.props.name)
    },
    // 切换时查看的状态 调用
    orderListShow (data) {
      this.activeName = data
      switch (data) {
        case 'HasResale': this.toShowList('1', data)
          break
        case 'InResale': this.toShowList('0', data)
          break
        default:
          break
      }
    },
    // 处理不同状态的切换展示效果
    toShowList (status, name) {
      this.ListData = []
      const getdata = {
        status: status,
        page: this.currentPage,
        size: 10
      }
      const loadingpage = this.$loading({
        lock: true,
        text: '加载中...'
      })
      // console.log(getdata)
      http.get('/mall-order/resale/getSelfList', getdata)
        .then(res => {
          console.log(res.data.data)
          this.ListData = res.data.data.records
          this.activeName = name
          loadingpage.close()
        }).catch(err => {
          loadingpage.close()
          console.log(err)
        })
      // for (const i in data) {
      //   if (data[i].status === name - 1) {
      //     this.orderDatashow.push(data[i])
      //   }
      // }
    },
    // 下架 开发票 按钮触发
    sumitbtn (act, item, index) {
      switch (act) {
        case 'HasResale':
          this.sendInviocedata = item
          this.ConfirmInvoiceDialog = true // 打开发票弹窗
          // console.log('HasResale', item)
          break
        case 'InResale':
          this.soldOut = item
          this.indexData = index
          console.log('InResale', item)
          break
        default:
          break
      }
    },
    confirmEvent () { // 下架确认
      // console.log('confirm!')
      http.get(`/mall-order/resale/delete/${this.soldOut.id}`)
        .then(res => {
          // console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({
              message: '下架成功!'
            })
            this.ListData.splice(this.indexData, 1)
          } else {
            ElMessage.error({
              message: res.data.msg
            })
          }
        }).catch(err => {
          console.log(err)
        })
      console.log(this.soldOut)
    },
    cancelEvent () { // 下架取消
      this.soldOut = ''
      // console.log('cancel!')
    },
    // 开发票 确定按钮
    ConfirmInvoiceBtn () {
      this.sendInvioceBtn = true
      http.get(`/mall-order/resale/sendInvioce/${this.sendInviocedata.id}`)
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({
              message: '开票成功!'
            })
          } else {
            ElMessage.warning({
              message: res.data.msg
            })
          }
          this.sendInvioceBtn = false
          this.ConfirmInvoiceDialog = false
          this.sendInviocedata = ''
        }).catch(err => {
          console.log(err)
          this.sendInvioceBtn = false
        })
    }
  },
  mounted () {
    // this.initdata() // 初始化数量
    this.orderListShow('InResale') // 初始化查询所有订单
    // this.initdata()
    emitter.on('toOrderPageNum', (data) => {
      // console.log(data)
      this.currentPage = data.val
      this.orderListShow(data.name)
    })
  },
  setup () {
    return {
    }
  }
}
</script>

<style lang="scss">
.showorder{
  padding: 0 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .tittle{
    height: 70px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 70px;
  }
  .bodystatus{
    position: relative;
    .el-tabs{
      .el-tabs__header{
        .el-tabs__nav-wrap{
          .el-tabs__nav-scroll{
            .el-tabs__nav{
              .el-tabs__active-bar{
                background: #C6824E;
              }
              .is-active{
                color: #C6824E;
              }
              .el-tabs__item:hover{
                color: #c49979;
              }
            }
          }
        }
      }
    }
    .div_orderlist{
      margin-top: 20px;
      width: 100%;
      .alltitle{
        width: 100%;
        background: #F7F7F7;
        border: 1px solid #EAEAEA;
        // margin-bottom: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .all_title_ul{
          height: 50px;
          padding: 0 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .all_title_li:first-child{
            width: 265px;
          }
          .all_title_li:nth-child(3){
            width: 200px;
          }
          .all_title_li:nth-child(5){
            width: 140px;
          }
          .all_title_li:not(:first-child):not(:nth-child(3)):not(:nth-child(5)){
            flex: 1;
          }
          .all_title_li{
            color: #999999;
          }
        }
      }
      .order_div{
        width: 100%;
        margin-bottom: 20px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        .order_title{
          background: #F7F7F7;
          border: 1px solid #EAEAEA;
          height: 50px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .order_title_p{
            // line-height: 50px;
            color: #999999;
          }
          .order_title_span{
            color: #999999;
            .order_title_button{
              margin-left: 20px;
              width: 100px;
              height: 30px;
              background: #FFFFFF;
              border: 1px solid #EAEAEA;
              border-radius: 4px;
              color: #666666;
            }
          }
        }

        .order_body{
          height: 120px;
          // background: hotpink;
          .order_body_ul{
            display: flex;
            .order_body_li{
              // flex: 1;
              height: 120px;
              box-sizing: border-box;
              padding: 20px;
              flex-flow: row nowrap;
              border: 1px solid #EAEAEA;
              color: #999999;
              .money_span,p{
                color: #333333;
              }
            }
            .order_body_li:first-child{
              width: 265px;
              .namebody{
                display: flex;
                img{
                  height: 80px;
                  width: 80px;
                }
                .name_time{
                  margin-left: 10px;
                  p{
                    width: 135px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #333333;
                    margin-bottom: 15px;
                  }
                  span{
                    color: #999999;
                  }
                }
              }
            }
            .order_body_li:nth-child(2){
              .li2_span_kg{
                color: #333333;
              }
            }
            .order_body_li:nth-child(3){
              width: 200px;
              .area_p{
                margin-top: 15px;
              }
            }
            .order_body_li:nth-child(5),.order_body_li:nth-child(6){
              width: 140px;
              display: flex;
              flex-flow: column;
              .status_name{
                color: #333333;
              }
              span,button{
                width: 100px;
                height: 30px;
              }
              button{
                background: #FFFFFF;
                border: 1px solid #C6824E;
                border-radius: 4px;
                color: #C6824E;
              }
              span,button:nth-child(1){
                margin-bottom: 10px;
              }
            }
            .order_body_li:not(:first-child):not(:nth-child(3)):not(:nth-child(5)):not(:nth-child(6)){
              flex: 1;
            }
            .order_body_li:last-child{
              .disabled_btn{
                border: 1px solid #858483;
                color: #858483;
                cursor: no-drop;
              }
            }
          }
        }
        .order_foot{
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #EAEAEA;
          padding: 0 20px;
          .order_foot_p{
            line-height: 40px;
            font-size: 14px;
            color: #C6824E;
          }
        }
      }
    }
  }
}
</style>
