<template>
<div class='cont' v-loading="loading" element-loading-text="查询中...">
  <h2>
    <span class="backspan" v-if="showAttStatus!=0" @click="tomessage">
      <el-icon><ArrowLeftBold /></el-icon>
    </span>
    {{showAttStatus==0 ? $t('m.personalcenter_myorder.Personal_Information') :
    showAttStatus==1 ? $t('m.personalcenter_myorder.Focus_on_commodities') :
    showAttStatus==2 ? $t('m.personalcenter_myorder.Focus_on_the_store') :$t('m.personalcenter_myorder.Browsing_history')}}</h2>
  <div class="body" v-if="showAttention">
    <span class="addspan">{{$t('m.personalcenter_myorder.Personal_Information_Details')}}</span>
    <div class="myuser">
      <div class="usermsg">
        <div class="icon">
          <div>
            <el-upload
              list-type="picture"
              :before-upload="beforeUpload"
              :on-success="guigeuploadSuccess"
              :action="upLoadUrl"
              :data="upLoadData"
              :on-remove="guigehandleRemove"
              :on-change="guigeonChange"
              accept="jpg/jpeg/png"
            >
            <img @click="imgIndex=k" :src="userList.icon" alt="">
            </el-upload>
          </div>
        </div>
        <div class="msg">
          <p>{{$t('m.personalcenter_myorder.User_Name')}}：<span class="userspan">{{userList.realName}}</span>
            <el-button size="mini" @click="undate('name')">{{$t('m.personalcenter_myorder.amend')}}</el-button>
          </p>
          <p>{{$t('m.personalcenter_myorder.Mobile_Number')}}：<span class="userspan">{{userList.phone}}</span></p>
          <p>{{$t('m.personalcenter_myorder.E_mail')}}：<span class="userspan">{{userList.email}}</span>
            <el-button class="yx" size="mini" @click="undate('Email')">{{this.Email?$t('m.personalcenter_myorder.amend'):$t('m.personalcenter_myorder.bind')}}</el-button>
          </p>
          <!-- <p>易货会员：<span class="userspan">{{userList.barterMember?'已开通':'未开通'}}</span>
            <el-button size="mini" :disabled="userList.barterMember?true:false" @click="undate('huiyuan')">开通</el-button>
          </p> -->
          <p>{{$t('m.personalcenter_myorder.Created_in')}}：<span class="userspan">{{userList.createTime}}</span></p>
        </div>
      </div>
      <div class="userattention">
        <div class="title">{{$t('m.personalcenter_myorder.My_concern')}}</div>
        <ul class="attention_ul">
          <li class="attention_li"
          v-for="i in attentionList" :key="i">
            <div @click="toatt(i)">
              <img :src="i.img" alt="">
              <p>{{i.cont}}<span v-if="i.num>0">({{i.num}})</span></p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="body" v-else>
    <!-- <span class="addspan"></span> -->
    <component :is="isShow">
    </component>
  </div>
</div>
<!-- 修改信息弹窗 -->
<div v-if="ConfirmDialog" class="dialog_div">
  <el-dialog v-model="ConfirmDialog" width="30%"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
    destroy-on-close
    :title="ischose == 'huiyuan'?'开通会员':$t('m.personalcenter_myorder.change_information')">
    <div class="namebody" v-if="ischose == 'name'">
      <el-input
        v-model="name"
        size="mini"
        maxlength="30">
        </el-input>
      <el-button :loading="loadingbtn"
      @click="ConfirmInvoiceBtn">{{$t('m.personalcenter_myorder.confirm')}}</el-button>
    </div>
    <!-- <div class="namebody" v-else-if="ischose == 'huiyuan'">
      <el-input
        size="mini"
        placeholder="请输入支付密码"
        v-model="password"
        type="password"
        maxlength="6"
        minlength="6">
        </el-input>
      <el-button :loading="loadingbtn"
      @click="ConfirmHuiyuanBtn">确认</el-button>
    </div> -->
    <div class="namebody" v-else>
      <el-input
        v-model="Email"
        size="mini"
        maxlength="30">
        </el-input>
      <el-button :loading="loadingbtn"
      @click="ConfirmEmailBtn">{{$t('m.personalcenter_myorder.confirm')}}</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment'
import { getStore, setStore } from '../../tools/storage'
import { ref } from 'vue-demi'
import http from '../../api/http'
import { ElMessage } from 'element-plus'
import Attention from './myMessageList/Attention.vue'
import storeAtten from './myMessageList/storeAtten.vue'
import browsingHistory from './myMessageList/browsingHistory.vue'

const updateInfo = (data) => {
  return http.post('/mall-portal/sso/updateInfo', data, true)
}
// const payBarterMembershipDues = (data) => { // 易货会员接口
//   return http.post('/mall-order/order/payBarterMembershipDues', data)
// }
export default {
  name: 'myMessage',
  data () {
    return {
      attentionList: [
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/title_img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83%E5%85%B3%E6%B3%A8%E5%95%86%E5%93%81.png',
          cont: this.$t('m.personalcenter_myorder.Product_fucos'),
          num: null
        },
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/title_img/%E5%85%AC%E4%BC%97%E5%8F%B7-%E5%85%B3%E6%B3%A8%E5%BA%97%E9%93%BA.png',
          cont: this.$t('m.personalcenter_myorder.Store_attention'),
          num: null
        },
        {
          img: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/title_img/%E6%B5%8F%E8%A7%88%E8%AE%B0%E5%BD%95.png',
          cont: this.$t('m.personalcenter_myorder.Browsing_history'),
          num: null
        }
      ], // 我的关注内容
      userList: '', // 保存个人信息
      showAttention: ref(true), // 控制当前展示的是否信息操作的内容
      showAttStatus: ref(0), // 展示的内容 0：个人信息 1：关注商品 2：关注店铺 3：浏览记录
      isShow: ref(''),
      loading: true,
      loadingbtn: ref(false),
      ConfirmDialog: ref(false), // 修改弹窗开关
      ischose: '',
      name: '', // 用户名称
      Email: '', // 用户邮箱
      password: '', // 易货会员密码

      // 上传头像
      dir: null,
      fileList: [],
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      }
    }
  },
  components: { Attention, storeAtten, browsingHistory },
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  methods: {
    async initData () { // 初始化个人信息
      http.get('/mall-portal/sso/info')
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            setStore('usermessage', res.data.data) // 存个人信息
            if (getStore('usermessage')) { // 判断有没有登录信息
              this.userList = JSON.parse(getStore('usermessage'))
              // console.log(this.userList)
              this.userList.createTime = moment(this.userList.createTime).format('YYYY-MM-DD')
              this.name = this.userList.realName
              this.Email = this.userList.email
              this.attentionList[0].num = this.userList.followTheNumberOfItems
              this.attentionList[1].num = this.userList.followTheNumberOfStores
              this.loading = false
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    undate (isc) { // 更新个人信息
      this.ischose = isc
      this.ConfirmDialog = true
    },
    async ConfirmInvoiceBtn () { // 弹窗确定按钮
      this.loadingbtn = true
      const usermessage = JSON.parse(getStore('usermessage')).type
      console.log(usermessage)
      const data = {
        // authCode: '',
        // companyName: '',
        // country: '',
        // icon: '',
        // phone: '',
        realName: this.name,
        type: usermessage === 1 ? 1 : null
      }
      const res = await updateInfo(data)
      console.log(res)
      if (res.data.code === 200) {
        this.loadingbtn = false
        ElMessage.success({
          message: '信息修改成功，请重新登录！'
        })
        this.$router.push('/loginandregister')
      }
    },
    async ConfirmEmailBtn () { // 确认邮箱按钮
      this.loadingbtn = true
      const testEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!testEmail.test(this.Email)) {
        ElMessage.warning({
          message: '邮箱可能不正确'
        })
        this.Email = ''
        this.loadingbtn = false
        return
      }
      const data = {
        email: this.Email
        // type: 1
      }
      const res = await updateInfo(data)
      console.log(res)
      if (res.data.code === 200) {
        this.loadingbtn = false
        ElMessage.success({
          message: '信息修改成功，请重新登录！'
        })
        this.$router.push('/loginandregister')
      }
    },
    // async ConfirmHuiyuanBtn () { // 开会员按钮
    //   const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount

    //   const md5password = this.$md5(`${capitalAccount}${this.password}`)
    //   const data = {
    //     password: md5password
    //   }
    //   const res = await payBarterMembershipDues(data)
    //   console.log(res)
    //   if (res.data.code === 200) {
    //     this.initData()
    //     this.password = ''
    //     this.ConfirmDialog = false
    //   }
    // },
    toatt (data) {
      switch (data.cont) {
        case '商品关注':
          this.showAttStatus = 1
          this.isShow = 'Attention'
          break
        case '店铺关注':
          this.showAttStatus = 2
          this.isShow = 'storeAtten'
          break
        case '浏览记录':
          this.showAttStatus = 3
          this.isShow = 'browsingHistory'
          break
        default:
          break
      }
      this.showAttention = false
    },
    tomessage () {
      this.showAttStatus = 0
      this.isShow = ''
      this.showAttention = true
    },
    beforeUpload (file) { // 上传图片触发方法
      // console.log(file)
      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 1024 // 这里做文件大小限制 // 暂时取消限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        return
      }
      if (!isLt2M) { // 暂时取消限制
        ElMessage({
          message: '上传文件大小不能超过 500k!',
          type: 'warning'
        })
        return
      }
      const param = {
        prefix: 'userIcon/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            console.log(res)
            this.dir = res.data.data.dir
            this.upLoadData.key = `${res.data.data.dir}/${file.name}`
            this.upLoadData.policy = res.data.data.policy
            this.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            this.upLoadData.signature = res.data.data.signature
            this.upLoadUrl = res.data.data.host
            // this.formData.specification_listData[this.imgIndex].price = file
            // console.log(file)
            // console.log(this.formData.specification_listData)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    guigeuploadSuccess (res, file) { // 图片改变触发方法
      // console.log(file)
      this.showFileList = true
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      setTimeout(() => {
        this.userList.icon = url
        this.unDataIcon(this.userList.icon)
        console.log(this.userList.icon)
      }, 1000)
    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      // this.formData.decript.dialogImageUrl = file.url
      // this.formData.decript.dialogVisible = true // 打开弹窗
    },
    guigehandleRemove (file) { // 删除图

    },
    guigeonChange (file, fileList) { // 暂时用不到
      if (fileList.length > 1) {
        fileList.splice(0, 1)
      }
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name
      this.userList.icon = url
    },
    async unDataIcon (icon) {
      this.loadingbtn = true
      const usermessage = JSON.parse(getStore('usermessage')).type

      const data = {
        icon: icon,
        type: usermessage === 1 ? 1 : null
      }
      const res = await updateInfo(data)
      console.log(res)
      if (res.data.code === 200) {
        this.loadingbtn = false
        ElMessage.success({
          message: '修改成功！'
        })
        this.initData()
      }
    }
  },
  mounted () {
    this.initData()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang="scss">
/deep/ .el-upload--picture-card{
        background: transparent;
        border: none;
        width: 100% !important;
        height: 100% !important;
        }
        .el-upload-list{
          display: none;
        }
</style>
<style lang='scss' scoped>
@import '../../style/viriables.scss';
.cont{
  font-family: Microsoft YaHei;
  h2{
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    color: $title;
    .backspan{
      position: relative;
      line-height: 50px;
      cursor: pointer;
      top: 2px;
    }
  }
  .body{
    .addspan{
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      background: #ebebeb;
      color: $title;
    }
    .myuser{
      display: flex;
      justify-content: space-between;
      .usermsg{
        display: flex;
        padding: 30px 0;
        .icon{
          padding: 0 30px;
          img{
            width: 100px;
            // height: 100px;
            border-radius: 50%;
          }
        }
        .msg{
          color: #666666;
          display: inline-block;
          .userspan{
            display: inline-block;
            min-width: 200px;
            line-height: 50px;
          }
          .el-button{
            margin-left: 20px;
          }
          .yx{
            background: $title;
            color: #ebebeb;
            border-radius: 4px;
          }
        }
      }
      .userattention{
        float: right;
        width: 400px;
        height: 200px;
        display: flex;
        flex-flow: column;
        border: 1px solid #d8d8d8;
        .title{
          padding: 0 20px;
          height: 50px;
          line-height: 50px;
          background: $title;
          color: #ebebeb;
          font-weight: bold;
        }
        .attention_ul{
          flex: 1;
          display: flex;
          justify-content: space-around;
          .attention_li{
            flex: 1;
            display: flex;
            flex-flow: column;
            align-items: center;
            div{
              cursor: pointer;
              flex: 1;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;
              img{
                width: 44px;
                height: 44px;
              }
              p{
                line-height: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow:ellipsis;
                span{
                  color: #ed150e;
                }
              }
            }
          }
        }
      }
    }
  }
}
.dialog_div{
  .el-overlay{
    .el-overlay-dialog{
      .el-dialog{
        .el-dialog__body{
          .namebody{
            .el-input{
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
