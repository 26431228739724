<template>
<!-- 浏览记录 -->
<div class='bshistory'>
  <div class="title">
    <el-button size="small" @click="isDeleteNow=!isDeleteNow">记录管理</el-button>
  </div>
  <div class="att_body" v-loading="loading" element-loading-text="查询中...">
    <div class="time_ul"  v-for="(i,ik) in attDataList" :key="ik">
      <h4 class="time_title">
        <p>{{i.date}}</p>
        <span class="delespan" v-show="!isDeleteNow">
          <el-icon size='20' @click="deleteHistory(i, ik)"><Delete /></el-icon>
        </span>
      </h4>
      <ul class="cont_ul">
        <li class="cont_li" v-for="(j,jk) in i.List" :key="jk">
          <div class="gzcont">
            <img :src="j.pic" alt="">
          </div>
          <p class="name">{{j.name}}</p>
          <p class="price">￥{{j.price}}<span>/kg</span></p>
        </li>
      </ul>
    </div>
    <div class="nodata" v-if="attDataList.length == 0">
      <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
    </div>
  </div>
  <div class="loading_foot">
    <el-button v-if="!nodata" :loading="btnLoading" @click="moreData">加载更多</el-button>
    <p v-else>没有更多数据</p>
  </div>
</div>
</template>

<script>
import { ref } from 'vue-demi'
import http from '../../../api/http'
import moment from 'moment'
import { ElMessage } from 'element-plus'

// 获取浏览记录
const queryPmsBrowsingHistoryByPage = (data) => {
  return http.get('/mall-portal/mall/pmsbrowsinghistory/queryPmsBrowsingHistoryByPage', data)
}
// 删除浏览记录
const pmsbrowsinghistorydelete = (data) => {
  return http.post('/mall-portal/mall/pmsbrowsinghistory/delete', data, true)
}
export default {
  name: 'browsingHistory',
  data () {
    return {
      allData: ref([]), // 接口接收的数据
      attDataList: ref([]), // 浏览记录数据 展示

      loading: ref(true), // 数据loading
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(30),
      // 默认展示页
      currentPage: ref(1),
      btnLoading: ref(false),
      nodata: ref(false), // 是否是最后一页
      isDeleteNow: ref(true)
    }
  },
  components: {},
  methods: {
    async initData () {
      const data = {
        pageNum: this.currentPage,
        pageSize: 30
      }
      const res = await queryPmsBrowsingHistoryByPage(data)
      if (res.data.code === 200) {
        console.log(res)
        this.allData = res.data.data.list
        if (res.data.data.list.length < res.data.data.pageSize) {
          this.nodata = true
        }
        // this.allData.push(res.data.data.list)
        const now = moment(new Date()).format('M月D日')
        this.allData.forEach(i => {
          // console.log(i)
          moment(i.createTime).format('M月D日') === now ? i.createTime = '今天' : i.createTime = moment(i.createTime).format('M月D日')
        })
        // this.attDataList = this.allData

        const dataArr = []
        this.allData.forEach(mapItem => {
          // console.log(mapItem)
          if (dataArr.length === 0) {
            dataArr.push({ date: mapItem.createTime, List: [mapItem] })
          } else {
            const resd = dataArr.some(item => { // 判断相同日期，有就添加到当前项
              if (item.date === mapItem.createTime) {
                item.List.push(mapItem)
                return true
              }
            })
            if (!resd) { // 如果没找相同日期添加一个新对象
              dataArr.push({ date: mapItem.createTime, List: [mapItem] })
            }
          }
        })
        // console.log(dataArr)
        dataArr.forEach(item => {
          const sd = this.attDataList.some(attitem => {
            if (item.date === attitem.date) {
              attitem.List.push(...item.List)
              return true
            }
          })
          if (!sd) { // 同上
            this.attDataList.push(item)
          }
        })
        console.log(this.attDataList)
        this.loading = false
        this.btnLoading = false
      }
    },
    moreData () {
      this.loading = true
      this.btnLoading = true
      this.currentPage++
      if (this.nodata) {
        console.log('没有更多数据')
      }
      this.initData()
    },
    deleteHistory (item, index) {
      console.log(item)
      console.log(index)
      this.$confirm(`您确定删除${item.date}的浏览记录吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = []
        item.List.forEach(i => {
          data.push(i.id)
        })
        const res = pmsbrowsinghistorydelete(data)
        res.then(succ => {
          if (succ.data.code === 200) {
            ElMessage.success({ message: succ.data.msg })
            this.attDataList.splice(index, 1)
          } else {
            ElMessage.warning({ message: succ.data.msg })
          }
        }).catch(err => {
          console.log(err)
        })
      }).catch(err => {
        return err
      })
    }
  },
  mounted () {
    this.initData()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.bshistory{
 font-family: Microsoft YaHei;
  .title{
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #ebebeb;
    float: right;
    .el-button{
      margin-top: 8px;
      float: right;
    }
  }
  .att_body{
    min-height: 666px;
    padding: 10px;
    .time_ul{
      .time_title{
        padding-left: 20px;
        // height: 16px;
        font-size: 18px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 50px;
        .delespan{
          // font-size: 25px;
          .el-icon{
            cursor: pointer;
          }
        }
      }
      .cont_ul{
        padding: 10px;
        display: flex;
        flex-flow: wrap;
        .cont_li{
          display: flex;
          flex-flow: wrap;
          padding: 18px;
          width: 25%;
          border: 1px solid #D2D2D2;
          box-sizing: border-box;
          // position: relative;
          overflow: hidden;
          .gzcont{
            width: 220px;
            height: 220px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .name{
            width: 100%;
            font-size: 16px;
            line-height: 50px;
            height: 50px;
            color: #333333;
          }
          .price{
            width: 100%;

            color: #EE313E;
            font-size: 17px;
            font-weight: 500;
            span{
              color: #999999;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .loading_foot{
    width: 100%;
    line-height: 150px;
    text-align: center;
    height: 150px;
    .el-button{
      width: 240px;
    }
  }
}
</style>
