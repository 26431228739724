<template>
<!-- 资金账户页面 -->
<div class='cont'>
  <h2 class="title">{{$t('m.personalcenter_myorder.Funding_account')}}</h2>
  <!-- <el-divider border-style="dashed"></el-divider> -->
  <div class="body">
    <ul class="zijin">
      <li class="ye">
        <div class="left">
          <img src="../../static/imges/available.png" alt="">
          <p>{{$t('m.personalcenter_myorder.Available_balance')}}：<span>{{balances}}</span></p>
        </div>
        <div class="right">
          <el-button @click="dialogVisible_outmoney = true,selectCard()">{{$t('m.personalcenter_myorder.Withdrawals')}}</el-button>
          <el-button @click="recharge">{{$t('m.personalcenter_myorder.recharge')}}</el-button>
          <!-- <p class="cz">提现<span></span></p>
          <p class="cz">充值<span></span></p> -->
        </div>
      </li>
      <li class="ye">
        <div class="left">
          <img src="../../static/imges/blocked.png" alt="">
          <p>{{$t('m.personalcenter_myorder.Frozen_balance')}}：<span>{{totalBlockedBalances}}</span></p>
        </div>
        <!-- <div class="right">
        </div> -->
      </li>
    </ul>
    <el-divider border-style="dashed"></el-divider>
    <h2>{{$t('m.personalcenter_myorder.Funding_details')}}</h2>
    <div>
      {{$t('m.personalcenter_myorder.Enquiry_Date')}}：
      <el-date-picker
      size="small"
        v-model="dateMon"
        type="daterange"
        placeholder="选择要查询的日期"
        :range-separator="$t('m.personalcenter_myorder.reach')"
        :start-placeholder="$t('m.personalcenter_myorder.start_date')"
        :end-placeholder="$t('m.personalcenter_myorder.date_closed')"
        format="YYYY-MM-DD"
        :clearable="false"
        @change="timechange"
      >
      </el-date-picker>
    </div>
    <div class="tableshow">
      <div id="table_body">
        <!-- v-infinite-scroll="load" infinite-scroll-disabled="busy" infinite-scroll-distance="100" -->
        <table
         v-loading="loading">
          <tr class="trtitle">
            <th v-for="(i,index) in quickList.title" :key="index">
              {{i}}
            </th>
          </tr>
          <tr v-for="(k,index1) in quickList.cont" :key="index1" >
            <td>{{k.batchNumber}}</td>
            <el-tooltip
              effect="light"
              :content="k.productName"
              placement="top"
            >
              <td>{{k.productName}}</td>
            </el-tooltip>
            <td class="money">
              <p>{{k.amount}}</p>
              <p v-if="k.refundStatus==1">{{$t('m.personalcenter_myorder.returned')}}:{{k.refundAmount}}</p>
              <p v-if="k.type==999||k.type==1332">
                {{k.type==999?`(${$t('m.personalcenter_myorder.thaw')})`:k.type==1332&&k.amount>0?`(${$t('m.personalcenter_myorder.freeze')})`:''}}
              </p>
            </td>
            <!-- <td>{{k.orderNo=='orderSn'?'未生成订单':k.orderNo}}</td> -->
            <td>{{k.orderNo}}</td>
            <td>{{k.createTime}}</td>
          </tr>
        </table>
        <!-- 资金明细分页 -->
        <div class="table_foot" >
          <el-pagination
            v-model:currentPage="currentPage"
            v-model:page-size="pagesize"
            :page-sizes="[10, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalnum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>

<!-- 充值弹框 -->
  <el-dialog
    v-model="dialogVisible_money"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :title="$t('m.personalcenter_myorder.recharge')"
    width="40%"
    :before-close="handleClose"
  >
    <div class="pay_body">
      <ul class="pay_qu">
        <!-- <li class="balancepay"
        :class="isactive=='balan'?'isact':''"
        @click="isactFun('balan')">
          富友充值
        </li> -->
        <li class="unionpay"
        :class="isactive=='union'?'isact':''"
        @click="isactFun('union')">
          <img src="../../static/imges/unionpay.png" alt="">
        </li>
        <!-- <li class="wechatpay"
        :class="isactive=='wechat'?'isact':''"
        @click="isactFun('balan')">
          <img src="../../static/imges/wechat.jpg" alt="">
        </li> -->
      </ul>
      <div v-if="isactive=='balan'">
        <el-input v-model="inputMoney" @change="confirmblAdbill('inputMoney')"
          maxlength="15"
        placeholder="请输入正确的充值金额(小数点后两位)">
          <template #prepend>{{$t('m.personalcenter_myorder.recharge_amount')}}</template>
        </el-input>
        <el-button class="subtn"
          @click="topupMoney"
          :disabled="disabled_money"
          size="">
          {{$t('m.personalcenter_myorder.Confirm_recharge')}}
        </el-button>
      </div>
      <div v-if="isactive=='union'">
        <div v-if="jsbankActive" class="jsbankno">
          <!-- <p>江苏银行股份有限公司</p> -->
          <!-- <span class="jsbankts">
            请使用资金账户绑定的企业网银账户向江苏银行股份有限公司的 <strong>{{jsbankCardNo}}</strong> 账户线下转账汇款
          </span> -->
          <el-descriptions
            title="请使用企业基本户转账汇款"
            direction="vertical"
            :column="4"
            size='large'
            border
          >
            <el-descriptions-item label="收款账户"><strong style="color:#C6824E;text-decoration:underline;font-size: 18px;">{{jsbankCardNo}}</strong></el-descriptions-item>
            <el-descriptions-item label="收款人">{{jsbankCardName}}</el-descriptions-item>
            <el-descriptions-item label="开户行">江苏银行股份有限公司昆山支行</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div v-show="false">
        <form name='yeepay' class="form-horizontal" method="post" :action="redirectUrl">
          <div class="form-group">
            <label class="col-sm-3 control-label">mchnt_cd</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="mchnt_cd"
                name="mchnt_cd" v-model="mchnt_cd">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">order_id</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="order_id"
                name="order_id" v-model="order_id">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">order_amt</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="order_amt"
                name="order_amt" v-model="order_amt">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">order_pay_type</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="order_pay_type"
                name="order_pay_type" v-model="order_pay_type">
            </div>
          </div>

          <div class="form-group">
            <label class="col-sm-3 control-label">page_notify_url</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="page_notify_url"
                name="page_notify_url"
                v-model="page_notify_url">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">back_notify_url</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="back_notify_url"
                name="back_notify_url"
                v-model="back_notify_url">
            </div>

          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">order_valid_time</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="order_valid_time"
                name="order_valid_time" v-model="order_valid_time">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">iss_ins_cd</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="iss_ins_cd"
                name="iss_ins_cd" v-model="iss_ins_cd">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">goods_name</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="goods_name"
                name="goods_name" v-model="goods_name">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">goods_display_url</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="goods_display_url"
                name="goods_display_url"
                v-model="goods_display_url">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">rem</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="rem" name="rem"
                v-model="rem">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">trx_iccid</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="trx_iccid" name="trx_iccid"
                v-model="trx_iccid">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">ver</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="ver" name="ver"
                v-model="ver">
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label">md5</label>
            <div class="col-sm-9">
              <input type="text" class="form-control" id="md5" name="md5"
                v-model="md5">
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-offset-3 col-sm-9">
              <button type="button" class="btn btn-default" @submit.prevent="pay">提交</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </el-dialog>

<!-- 提现弹框 -->
  <el-dialog
    v-model="dialogVisible_outmoney"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :title="$t('m.personalcenter_myorder.Withdrawals')"
    width="40%"
    :before-close="handleClose"
  >
    <div class="out_list">
      <el-radio-group v-model="virtualCardInfoValue" @change="virtualChange">
        <el-radio-button v-for="i in virtualCardInfoList" :key="i.id"
          :label="i.bankCh + '：' + i.balances" />
      </el-radio-group>
    </div>
    <div class="out_pay">
      <el-input v-model="out_inputMoney" placeholder="请输入正确的提现金额(小数点后两位)"
      @change="confirmblAdbill('out_inputMoney')"
      maxlength="15">
        <template #prepend>{{$t('m.personalcenter_myorder.Withdrawal_amount')}}</template>
      </el-input>
      <el-input
        placeholder="请输入支付密码"
        v-model="password"
        type="password"
        maxlength="6"
        minlength="6">
      </el-input>
      <!-- <div class="radios">
        <el-radio v-model="outRadio" @change="selectCard" label="1">提现到已绑银行卡</el-radio>
        <el-radio v-model="outRadio" @change="selectCard" label="0">提现到其他银行卡</el-radio>
      </div> -->
      <div class="showcard" v-if="outRadio=='0'">
        <el-input
          placeholder="请输入银行卡号"
          v-model="bankCardNum"
          maxlength="18"
          minlength="15">
        </el-input>
        <el-input
          placeholder="请输入账户名"
          v-model="bankCardName"
          minlength="2">
        </el-input>
      </div>
      <div class="showcard" v-else>
        <ul v-loading="bankLoading">
          <li v-for="(i,k) in bankList" :key="k"
          v-show="i.auditStatus==3"
          :class="{active:isBankActive==k}"
          @click="choseBank(i,k)">
            <span class="isact"><icon-svg :icon-class="tobankIcon(i.bankName)" /></span>
            <div class="bottom">
              <p class="msg"><span>{{i.bankName}}</span></p>
              <p class="msg"><span>{{i.bankCardNo}}</span></p>
            </div>
          </li>
        </ul>
      </div>
      <el-button class="subtn"
        @click="outMoney"
        :disabled="disabled_outmoney"
        size="">{{$t('m.personalcenter_myorder.Confirm_Withdrawal')}}</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import moment from 'moment'
import http from '../../api/http'
import { getStore, setStore } from '../../tools/storage'
import { ElMessage } from 'element-plus'
import { ref } from 'vue-demi'
import emitter from '../../tools/bus'

// 资金明细查询
const getDetails = (data) => {
  return http.post('mall-finance/finance/fmscapitaldetails/getDetails', data, true)
  /* 返回的type数据
  type 资金类型：
  \r\n270--出金
  \r\n271--入金
  \r\n272--付款
  \r\n273--退款
  \r\n430--付跨境产业电商手续费
  \r\n431--付跨境产业电商保证金
  \r\n432--退跨境产业电商保证金
  \r\n433--付跨境产业电商货款
  \r\n434--收跨境产业电商货款
  \r\n435--付跨境产业电商违约金
  \r\n436--收跨境产业电商违约金
  \r\n999—冻结资金（正数冻结，负数解冻）'
*/
}
// 请求余额明细
const getBalances = (data) => {
  return http.get(`mall-finance/finance/capital/getBalances/${data}`, {})
}
// 聚合支付 记录
const orderPay = (data) => {
  return http.post('mall-finance/fuiou/orderPay', data)
}
// 富友出金
const outfund = (data) => {
  return http.post('mall-finance/fuiou/outfund', data)
}
// 江苏银行出金
const outDetails = (data) => {
  return http.post('mall-finance/jiangSuBank/outDetails', data)
}
// 查询银行卡列表
const queryBankCardByCapitalAccount = (data) => {
  return http.get('mall-finance/finance/capital/queryBankCardByCapitalAccount', data)
}

// 虚拟账户列表+信息详情
const virtualCardInfo = (data) => {
  return http.post('mall-finance/finance/fmsbankvirtualcardinfo/virtualCardInfo', data, true)
}
export default {
  name: 'isMoney',
  data () {
    return {
      balances: 0, // 可用余额
      totalBlockedBalances: 0, // 冻结余额
      tixianbalances: '', // 可提现余额
      quickList: {
        title: [
          this.$t('m.personalcenter_myorder.Payment_batch_number'),
          this.$t('m.personalcenter_myorder.product_name'),
          this.$t('m.personalcenter_myorder.amount'),
          this.$t('m.personalcenter_myorder.Pay_the_order_number'),
          this.$t('m.personalcenter_myorder.Created_in')
        ],
        cont: []
      },
      totalnum: 0,
      pagesize: ref(10), // 存放一页显示个数
      currentPage: ref(1), // 默认展示第几页

      loading: ref(true), // 加载
      busy: false,
      pageIndexNum: 1,
      dateMon: ref(''),
      nodata: false, // 判断是不是数据的最后一页
      // 充值
      dialogVisible_money: ref(false), // 弹窗dialog
      isactive: 'union',
      inputMoney: '', // 充值金额 分
      disabled_money: ref(false), // 按钮禁用
      jsbankActive: false, // 是否开通江苏银行
      jsbankCardNo: '', // 江苏银行虚拟账户
      jsbankCardName: '', // 江苏银行账户收款人
      // 参数
      redirectUrl: 'http://www-2.wg.fuioupay.com:13195/smpGate.do',
      mchnt_cd: '0001000F0040992',
      order_id: '20220629193723',
      order_amt: 100,
      order_pay_type: 'B2C',
      page_notify_url: 'http://test.exp2c.com/#/',
      back_notify_url: 'http://fox1024.gnway.cc/fuiou/orderPayNotify',
      order_valid_time: '10m',
      iss_ins_cd: '0000000000',
      goods_name: '充值',
      goods_display_url: 'http://www-2.wg.fuioupay.com:13195/pay_test/callback.jsp',
      rem: 'rem',
      trx_iccid: '',
      ver: '1.0.1',
      md5: ref(''),
      mchnt_key: 'vau6p7ldawpezyaugc0kopdrrwm4gkpu',

      regMoney: /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,2})?$/, // 金额正则
      bankNumber: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/, // 银行卡号正则

      // 出金
      dialogVisible_outmoney: ref(false),
      disabled_outmoney: ref(false),
      password: '',
      out_inputMoney: '',
      outRadio: '1',
      bankCardNum: '',
      bankCardName: '',
      bankList: ref([]),
      bankLoading: ref(true),
      isBankActive: 0, // 选中的银行卡
      isBankData: '', // 选中的银行卡参数

      virtualCardInfoValue: '', // 选择的出金方式
      virtualCardInfoChose: '', // 出金选择
      virtualCardInfoList: [], // 可出金方式（金额）列表
      bankCardId: ''
    }
  },
  components: {},
  methods: {
    isactFun (k) {
      this.isactive = k
    },
    confirmblAdbill (str) {
      switch (str) {
        case 'inputMoney':
          this.inputMoney = (parseInt(this.inputMoney * 10000) / 10000).toFixed(2)

          if (!this.regMoney.test(this.inputMoney)) {
            this.inputMoney = ''
          }
          break
        case 'out_inputMoney':
          this.out_inputMoney = (parseInt(this.out_inputMoney * 10000) / 10000).toFixed(2)

          if (!this.regMoney.test(this.out_inputMoney)) {
            this.out_inputMoney = ''
          }
          break
        default:
          break
      }
    },
    async initYedata () { // 获取余额明细
      const userAccount = JSON.parse(getStore('usermessage')).userAccount
      const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
      // console.log(userAccount)
      // console.log(capitalAccount)
      const res = await getBalances(userAccount)
      // console.log(res)
      if (res.data.msg === '查询余额成功' && capitalAccount) {
        // console.log(res)
        this.balances = res.data.data.totalBalances
        this.totalBlockedBalances = res.data.data.totalBlockedBalances
        // this.accshow = true
        return ''
      } else {
        this.totalBlockedBalances = 0
        this.balances = 0
        ElMessage.error({
          message: res.data.msg
        })
        emitter.emit('toUserShow', 2)
      }
    },
    async initZjdata () { // 获取资金明细
      const userAccount = JSON.parse(getStore('usermessage'))
      const dataMoneydetail = {
        customerAccount: userAccount.userAccount,
        // date: this.dateMon,
        beginDate: this.dateMon[0],
        endDate: this.dateMon[1],
        pageIndex: this.pageIndexNum,
        pageSize: this.pagesize
      }
      this.loading = true
      const moneydetail = await getDetails(dataMoneydetail)
      console.log(moneydetail)
      if (moneydetail.data.code === 200) {
        if (moneydetail.data.data.capitalDetails.list.length < moneydetail.data.data.capitalDetails.pageSize) {
          this.nodata = true
        }
        for (const i of moneydetail.data.data.virtualCardInfoList) {
          if (i.bankEn === 'JS_BANK') {
            // i.stock += j.stock
            this.jsbankActive = true
            this.jsbankCardNo = i.bankCardNo
            break
          }
        }
        this.tixianbalances = moneydetail.data.data.balances // 可提现余额
        this.quickList.cont = moneydetail.data.data.capitalDetails.list
        this.totalnum = moneydetail.data.data.capitalDetails.totalCount
        this.loading = false
      } else {
        this.loading = false
        this.nodata = true // 没有数据
      }
      // console.log(moneydetail)
    },
    handleSizeChange (val) {
      this.pagesize = val
      this.pageIndexNum = 1
      this.initZjdata()
    },
    handleCurrentChange (val) {
      this.pageIndexNum = val
      this.initZjdata()
    },
    // load: function () { // 滚动条加载
    //   this.busy = true
    //   console.log('加载加载')
    //   this.pageIndexNum++
    //   if (this.nodata) {
    //     this.busy = true
    //     this.pageIndexNum = 1
    //     return
    //   }
    //   this.initZjdata()
    //   this.busy = false
    // },
    timechange () { // 改变时间触发
      this.dateMon[0] = moment(this.dateMon[0]).format('YYYY-MM-DD')
      this.dateMon[1] = moment(this.dateMon[1]).format('YYYY-MM-DD')
      this.quickList.cont = [] // 展示的数据清空
      this.pageIndexNum = 1 // 默认查询的是第一页
      console.log(this.dateMon)
      this.busy = false
      // this.load()
      this.initZjdata()
      // this.load()
    },
    async recharge () { // 充值按钮
      // console.log('触发')
      const virtualdata = {
        customerAccount: JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null
      }
      const virtualres = await virtualCardInfo(virtualdata)
      console.log(virtualres)
      if (virtualres.data.code === 200) {
        // console.log('充值调用')
        for (const i of virtualres.data.data.list) {
          // console.log(i)
          if (i.bankEn === 'JS_BANK') {
            this.jsbankActive = true
            this.jsbankCardName = i.name
            break
          }
        }
        this.dialogVisible_money = true
      } else {
        ElMessage.warning({
          message: virtualres.data.msg
        })
      }
    },
    async selectCard () { // 切换提现银行卡
      this.bankLoading = true
      this.bankCardNum = ''
      this.bankCardName = ''
      // console.log('卡')
      if (this.outRadio === '1') {
        // console.log('看看卡')
        const capitalAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).capitalAccount : null
        const virtualdata = {
          customerAccount: JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null
        }
        const virtualres = await virtualCardInfo(virtualdata)
        console.log(virtualres)
        if (virtualres.data.code === 200) {
          const arr = virtualres.data.data.list.find(i => {
            return i.bankEn === 'JS_BANK'
          })
          // console.log(arr)
          this.virtualCardInfoList = [arr]
          this.virtualCardInfoValue = this.virtualCardInfoList[0].bankCh + '：' + this.virtualCardInfoList[0].balances
          this.virtualChange(this.virtualCardInfoValue)
          // this.virtualCardInfoList = virtualres.data.data.list
        } else {
          ElMessage.warning({
            message: virtualres.data.msg
          })
        }
        const data = {
          capitalAccount: capitalAccount
        }
        const res = await queryBankCardByCapitalAccount(data)
        console.log(res)
        if (res.data.code === 200) {
          this.bankList = res.data.data
          if (this.bankList.length !== 0) {
            this.choseBank(this.bankList[0], 0)
          } else {
            ElMessage.warning({
              message: '请绑定银行卡！'
            })
            emitter.emit('toUserShow', 3)
          }
          this.bankLoading = false
        } else {
          this.bankLoading = false
          ElMessage.warning({
            message: res.data.msg
          })
        }
      }
    },
    virtualChange (data) { // 切换提现方式
      console.log(data)
      // console.log(this.virtualCardInfoValue)
      for (const i of this.virtualCardInfoList) {
        if (data === i.bankCh + '：' + i.balances) {
          this.bankCardId = i.bankCardId
          this.virtualCardInfoChose = i.bankCh
          break
        }
      }
      console.log(this.bankCardId)
      console.log(this.virtualCardInfoChose)
    },
    choseBank (item, index) {
      this.isBankActive = index
      // console.log(item)
      this.bankCardNum = item.bankCardNo
      this.bankCardName = item.name
      this.isBankData = item
    },
    tobankIcon (data) { // 显示图标
      return data.indexOf('工商') !== -1 ? 'yinhangbiaozhi_gongshangyinhang'
        : data.indexOf('建设') !== -1 ? 'jiansheyinhang1'
          : data.indexOf('农业') !== -1 ? 'nongyeyinhang'
            : data.indexOf('中国银行') !== -1 ? 'yinhang-zhongguoyinhang'
              : data.indexOf('邮政') !== -1 ? 'yinhang-youzhengchuxu'
                : data.indexOf('交通') !== -1 ? 'zhongguojiaotongyinhang'
                  : data.indexOf('招商') !== -1 ? 'zhaoshangyinhang1'
                    : data.indexOf('浦发') !== -1 ? 'pufayinhang'
                      : data.indexOf('广发') !== -1 ? 'pufayinhang'
                        : data.indexOf('光大') !== -1 ? 'guangdayinhang'
                          : data.indexOf('民生') !== -1 ? 'minshengyinxing'
                            : data.indexOf('中信') !== -1 ? 'zhongxinyinhangbank1193438easyiconnet'
                              : data.indexOf('华夏') !== -1 ? 'changyonglogo17'
                                : data.indexOf('兴业') !== -1 ? 'xingyeyinhang'
                                  : data.indexOf('上海银行') !== -1 ? 'shanghaiyinxing'
                                    : data.indexOf('北京银行') !== -1 ? 'beijingyinxing'
                                      : 'yinlian'
    },
    handleClose () { // 关闭弹窗
      this.dialogVisible_money = false
      this.dialogVisible_outmoney = false
    },
    async topupMoney () { // 充值确定
      this.disabled_money = true
      // const nowTime = new Date().getTime()
      const userAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null
      if (this.inputMoney === '' || !this.regMoney.test(this.inputMoney)) {
        this.disabled_money = false
        this.inputMoney = ''
        ElMessage.warning({
          message: '请输入正确的金额'
        })
        return
      }
      // this.inputMoney = this.inputMoney * 10000 / 100
      const tooutMoney = this.inputMoney

      const data = {
        order_amt: tooutMoney,
        // order_id: nowTime,
        customerAccount: userAccount
        // goods_name: '充值'
      }
      // console.log(data)
      const res = await orderPay(data)
      console.log(res)
      if (res.data.code === 200) {
        const formValue = res.data.data
        this.order_id = formValue.order_id
        this.order_amt = formValue.order_amt
        this.md5 = formValue.md5
        this.back_notify_url = formValue.back_notify_url
        this.goods_display_url = formValue.goods_display_url
        this.goods_name = formValue.goods_name
        this.iss_ins_cd = formValue.iss_ins_cd
        this.mchnt_cd = formValue.mchnt_cd
        this.order_pay_type = formValue.order_pay_type
        this.order_valid_time = formValue.order_valid_time
        this.page_notify_url = formValue.page_notify_url
        this.rem = formValue.rem
        this.trx_iccid = formValue.trx_iccid
        this.ver = formValue.ver
        this.redirectUrl = formValue.url
        setStore('tooutMoney', tooutMoney)
        // console.log(this.back_notify_url)
        // this.order_id = nowTime
        // this.order_amt = tooutMoney
        // const plain = this.mchnt_cd + '|' +
        // this.order_id + '|' +
        // this.order_amt + '|' +
        // this.order_pay_type + '|' +
        // this.page_notify_url + '|' +
        // this.back_notify_url + '|' +
        // this.order_valid_time + '|' +
        // this.iss_ins_cd + '|' +
        // this.goods_name + '|' +
        // this.goods_display_url + '|' +
        // this.rem + '|' +
        // this.ver + '|' +
        // this.mchnt_key
        // this.md5 = this.$md5(plain)

        setTimeout(() => {
          document.yeepay.submit()
        }, 200)
      } else {
        ElMessage.error({
          message: res.data.msg
        })
      }
    },
    async outMoney () { // 提现确定
      this.disabled_outmoney = true
      console.log(this.isBankData)
      if (!/^\d{6}$/.test(this.password)) {
        this.password = ''
        this.disabled_outmoney = false
        ElMessage.warning({
          message: '请输入正确的密码'
        })
        return
      }
      if (!this.regMoney.test(this.out_inputMoney) && this.out_inputMoney === '') {
        ElMessage.warning({
          message: '请输入正确的金额'
        })
        this.out_inputMoney = ''
        this.disabled_outmoney = false
        return
      }
      if (!this.bankNumber.test(this.bankCardNum) && this.bankCardNum === '') {
        ElMessage.warning({
          message: '请输入正确的银行卡号'
        })
        this.bankCardNum = ''
        this.disabled_outmoney = false
        return
      }
      if (this.virtualCardInfoValue === '') {
        ElMessage.warning({
          message: '请选择提现类型'
        })
        this.password = ''
        this.bankCardNum = ''
        this.out_inputMoney = ''
        this.disabled_outmoney = false
        return
      }

      // const nowTime = new Date().getTime()
      const passwords = this.password
      const capitalAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).capitalAccount : null // 资金账号
      const customerAccount = JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null // 所属账号
      const md5password = this.$md5(`${capitalAccount}${passwords}`)
      // this.out_inputMoney = this.out_inputMoney * 10000 / 100
      let data = {}
      const tooutMoney = this.out_inputMoney
      if (this.virtualCardInfoChose === '富友支付') {
        if (this.isBankData.registrationType === '个人') {
          data = {
            capitalAccount: capitalAccount,
            customerAccount: customerAccount,
            password: md5password,
            amt: tooutMoney,
            accntno: this.bankCardNum,
            accntnm: this.isBankData.name,
            payeeCertNo: this.isBankData.idNumber,
            mobile: this.isBankData.phone
          }
        } else {
          data = {
            capitalAccount: capitalAccount,
            customerAccount: customerAccount,
            password: md5password,
            amt: tooutMoney,
            bankno: this.isBankData.bankNameCode,
            cityno: this.isBankData.cityCode,
            branchnm: this.isBankData.openBankName,
            accntno: this.bankCardNum,
            accntnm: this.bankCardName,
            mobile: this.isBankData.phone,
            payeeCertNo: this.isBankData.idNumber,
            payeeCertTp: this.isBankData.typeOfCertificate
          }
        }
        console.log(data)
        this.disabled_outmoney = false
        const res = await outfund(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          // this.balances -= this.out_inputMoney
          this.initYedata()
          this.password = ''
          this.out_inputMoney = ''
          this.disabled_outmoney = false
          this.dialogVisible_outmoney = false
        } else {
          ElMessage.error({
            message: res.data.msg
          })
          this.password = ''
          this.out_inputMoney = ''
          this.disabled_outmoney = false
          this.dialogVisible_outmoney = false
        }
      } else if (this.virtualCardInfoChose === '江苏银行') {
        data = {
          amount: this.out_inputMoney * 10000 / 100,
          balanceAcctId: this.bankCardId,
          bankAcctNo: this.isBankData.bankCardNo,
          businessType: '1',
          customerAccount: customerAccount,
          idCard: this.isBankData.idNumber,
          password: md5password
        }
        console.log(data)
        const res = await outDetails(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          // this.balances -= this.out_inputMoney
          this.initYedata()
          this.password = ''
          this.out_inputMoney = ''
          this.disabled_outmoney = false
          this.dialogVisible_outmoney = false
        } else {
          ElMessage.error({
            message: res.data.msg
          })
          this.password = ''
          this.out_inputMoney = ''
          this.disabled_outmoney = false
          this.dialogVisible_outmoney = false
        }
      }
    }
  },
  mounted () {
    this.dateMon = ref([
      moment(new Date()).subtract(1, 'M').format('YYYY-MM'),
      moment(new Date()).add(1, 'M').format('YYYY-MM')
    ])
    console.log(this.dateMon)
    this.initZjdata()
    this.initYedata()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
@import '../../style/viriables.scss';
.cont{
  font-size: 16px;
  font-family: Microsoft YaHei;
  .title{
    color: $title;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px dashed #cccccc;
  }
  .body{
    padding: 24px 20px 0;
    .zijin{
      display: flex;
      .ye{
        // display: flex;
        width: 420px;
        .left{
          display: flex;
          align-items: center;
          img{
            width: 64px;
            height: 50px;
          }
          p{
            flex: 1;
            padding-left: 10px;
            font-weight: bold;
            color: #000000;
          }
        }
        .right{
          margin-top: 20px;
          display: flex;
          justify-content: space-evenly;
        }
      }
    }
    h2{
      color: $title;
    }
    .tableshow{
      margin-top: 20px;
      // background: #ffffff;
      #table_body{
        box-sizing: border-box;
        display: flex;
        flex-flow: column;
        min-height: 600px;
        // overflow-y: auto;
        tr{
          display: flex;
          text-align: center;
          th{
            border: 1px solid #cccccc;
            flex: 1;
            text-align: center;
            // font-size: 14px;
            height: 50px;
            line-height: 50px;
            font-weight: bold;
            background: $title;
            color: #ffffff;
            white-space:nowrap; // 一行显示
            text-overflow: ellipsis;
            overflow: hidden;
          }
          td{
            border: 1px solid #cccccc;
            flex:1;
            color: #666666;
            font-size: 14px;
            height: 50px;
            line-height: 50px;
          }
          .money{
            display: flex;
            justify-content: center;
            p{
              margin: 0 5px;
            }
          }
          td:not(:nth-child(3)){
            white-space:nowrap; // 一行显示
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .table_foot{
          width: 100%;
          display: flex;
          justify-content: center;
          padding: 20px 0;
        }
      }
    }
  }
  .el-overlay{
    .el-dialog{
      .el-dialog__body{
        .pay_body{
          // width: 473px;
          width: 100%;
          display: flex;
          flex-flow: column;
          .pay_qu{
            display: flex;
            justify-content: space-between;
            height: 60px;
            margin-bottom: 20px;
            .balancepay{
              text-align: center;
              font-size: 18px;
              line-height: 60px;
            }
            li{
              width: 226px;
              height: 60px;
              z-index: 2;
              transition: all 0.2s;
              border: 2px solid #EAEAEA;
              img{
                display: block;
                height: 60px;
                margin: 0 auto;
              }
            }
            .unionpay{
              position: relative;
              img{
                z-index: 1;
              }
            }
            .isact{
              border-color: #C6824E;
            }
          }
          .pay_bank{
            li{
              height: 60px;
              background: #FFFFFF;
              border: 1px solid #EAEAEA;
              margin-bottom: 20px;
            }
            .add_bank_card{
              display: block;
              text-align: center;
              line-height: 60px;
              width: 227px;
              height: 60px;
              background: #FFFFFF;
              border: 1px solid #EAEAEA;
              cursor: pointer;

            }
          }
          .jsbankno{
            max-width: 900px;
          }
        }
        .subtn{
          margin: 20px 0;
          min-width: 120px;
        }
        .out_pay{
          padding: 0 40px;
          .showcard{
            padding: 20px 0;
            ul{
              min-height: 50px;
              li{
                padding: 0 20px;
                color: #999999;
                border: 1px solid transparent;
                transition: all 0.2s;
                display: flex;
                .isact{
                  display: inline-block;
                  font-size: 35px;
                  line-height: 40px;
                }
                .bottom{
                  display: flex;
                  align-items: center;
                  flex: 1;
                  p{
                    flex: 1;
                    padding:0 20px;
                  }
                }
              }
              .active{
                color: #333333;
                background: #FFEEE0;
                border: 1px solid #C6824E;
                i{
                  color: #C6824E;
                }
              }
            }
          }
        }
        .out_list{
          padding: 20px 40px;
        }
      }
    }
  }
}
</style>
