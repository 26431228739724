<template>
<!-- 开通资金账户页面 -->
<div class='main' v-loading="loadingMystatus">
  <div class="zijin_top">
    <h2>{{$t('m.personalcenter_myorder.Open_a_funding_account')}}</h2>
    <div class="zijin_title" v-if="!MyStatus">
      <el-icon><warning-filled /></el-icon>
      <span class="tishi">{{logText}}</span>
    </div>
    <div class="zijin_title" v-if="MyStatus">
      <!-- <el-icon><warning-filled /></el-icon> -->
      <span class="tishi">
        <strong style="font-size:25px;">
        {{thisStatus==0 ?$t('m.personalcenter_myorder.unreviewed'):
          thisStatus==1 ?$t('m.personalcenter_myorder.has_been_opened'):
          thisStatus==2 ?$t('m.personalcenter_myorder.To_be_verified'):
          thisStatus==3 ?$t('m.personalcenter_myorder.Paid_for_review'):
          thisStatus==4 ?$t('m.personalcenter_myorder.be_rejected'): $t('m.personalcenter_myorder.unknown_state')}}
        </strong>
        {{customerType==0?$t('m.personalcenter_myorder.A_personal_account'):$t('m.personalcenter_myorder.Enterprise_legal_person_account')}}
      </span>
      <el-button size="small"
      v-if="thisStatus==2"
      @click="yzMoneyBtn"
      >{{$t('m.personalcenter_myorder.Verify_fund_account')}}<el-icon><ArrowRight /></el-icon></el-button>
    </div>
    <div class="zijin_chose" v-if="!MyStatus">
      <span class="chose_span"><i style="color:red;">*</i> {{$t('m.personalcenter_myorder.Account_Classification')}}</span>
      <el-radio-group v-model="radio" @change="isradio">
        <!-- <el-radio label="1">个人开通</el-radio> -->
        <el-radio label="2">{{$t('m.personalcenter_myorder.Enterprise_opening')}}</el-radio>
      </el-radio-group>
    </div>
  </div>

  <div class="zijin_body">
    <div class="from_wenzi">
      <h2>{{radio=='1'?'个人信息':$t('m.personalcenter_myorder.company_information')}}</h2>
      <div class="from_geren" v-if="radio=='1'">
        <el-form ref="formData" :model="formData" :rules="rules"
        label-width="180px" label-suffix="：">
          <el-form-item label="姓 名" prop="name">
            <el-input v-model="formData.name" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="identity">
            <el-input v-model="formData.identity" :disabled="MyStatus"
            @change="confirmblAdbill('sfz')"
             size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model="formData.phone" size="small" disabled></el-input>
          </el-form-item>
          <el-form-item label="资金密码" prop="moneypsd">
            <el-input v-model="formData.moneypsd" :disabled="MyStatus"
            type="password"
            maxlength="6"
             size="small"></el-input>
          </el-form-item>
          <el-form-item label="确认资金密码" prop="resultMoneypsd" v-if="!MyStatus">
            <el-input v-model="formData.resultMoneypsd"
            type="password"
            maxlength="6"
             size="small"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="from_geren" v-else>
        <el-form ref="formData" :model="formData" :rules="rules"
         label-width="180px" label-suffix="：">
          <el-form-item :label="$t('m.personalcenter_myorder.Company_Name')" prop="qyName">
            <el-input v-model="formData.qyName" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Company_Address')" prop="enterpriseAddress">
            <el-input v-model="formData.enterpriseAddress" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="'经办人姓名'" prop="operatorName">
            <el-input v-model="formData.operatorName" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="'经办人手机号'" prop="operatorPhoneNumber">
            <el-input v-model="formData.operatorPhoneNumber" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="'经办人身份证号'" prop="operatorIDNumberNo" v-if="!MyStatus">
            <el-input v-model="formData.operatorIDNumberNo" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Business_Period')" prop="businessLicenseValidTime" v-if="!MyStatus">
            <!-- <el-date-picker
              v-model="formData.businessLicenseValidTime"
              type="date"
              placeholder="营业期限"
              value-format="YYYY-MM-DD"
              size="small"
            /> -->
            <div class="time_switch">
              <span style="color:#606266;">{{$t('m.personalcenter_myorder.Whether_it_is_long_term')}}：</span>
              <el-switch
                v-model="businessTimeSwitch"
                inline-prompt
                :active-text="$t('m.personalcenter_myorder.be')"
                :inactive-text="$t('m.personalcenter_myorder.not')" />
            </div>
            <el-date-picker
              v-model="formData.businessLicenseValidBegin"
              type="date"
              :disabledDate="pickerOptions.disabledNtomDate"
              :placeholder="$t('m.personalcenter_myorder.start_date')"
              value-format="YYYY-MM-DD"
              size="small"
            />
            <el-date-picker
            v-if="!businessTimeSwitch"
              v-model="formData.businessLicenseValidEnd"
              type="date"
              :placeholder="$t('m.personalcenter_myorder.date_closed')"
              value-format="YYYY-MM-DD"
              size="small"
            />
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Social_Unified_Authentication_Code')" prop="qyNumber">
            <el-input v-model="formData.qyNumber" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Organization_Code')" prop="organizationCertNumber" v-if="!MyStatus">
            <el-input v-model="formData.organizationCertNumber" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Organization_Certificate_Validity_Period')" required v-if="!MyStatus">
            <div class="time_switch">
              <span style="color:#606266;">{{$t('m.personalcenter_myorder.Whether_it_is_long_term')}}：</span>
              <el-switch
                v-model="organizationCerTimeSwitch"
                inline-prompt
                :active-text="$t('m.personalcenter_myorder.be')"
                :inactive-text="$t('m.personalcenter_myorder.not')" />
            </div>
            <el-date-picker
              v-model="formData.organizationCertValidBegin"
              type="date"
              :disabledDate="pickerOptions.disabledNtomDate"
              :placeholder="$t('m.personalcenter_myorder.start_date')"
              value-format="YYYY-MM-DD"
              size="small"
            />
            <el-date-picker
            v-if="!organizationCerTimeSwitch"
              v-model="formData.organizationCertValidEnd"
              type="date"
              :placeholder="$t('m.personalcenter_myorder.date_closed')"
              value-format="YYYY-MM-DD"
              size="small"
            />
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Name_of_legal_representative')" prop="name">
            <el-input v-model="formData.name" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Corporate_Identity_Card')" prop="identity">
            <el-input v-model="formData.identity" :disabled="MyStatus" @change="confirmblAdbill('sfz')" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.ID_Card_Validity_Period')" prop="idcardValidityBegin">
            <div class="time_switch" v-if="!MyStatus">
              <span style="color:#606266;">{{$t('m.personalcenter_myorder.Whether_it_is_long_term')}}：</span>
              <el-switch
                v-model="idcardValidityTimeSwitch"
                inline-prompt
                :active-text="$t('m.personalcenter_myorder.be')"
                :inactive-text="$t('m.personalcenter_myorder.not')" />
            </div>
            <el-date-picker
              v-model="formData.idcardValidityBegin"
              :disabled="MyStatus"
              :disabledDate="pickerOptions.disabledNtomDate"
              type="date"
              :placeholder="$t('m.personalcenter_myorder.start_date')"
              value-format="YYYY-MM-DD"
              size="small"
            />
            <el-date-picker
            v-if="!idcardValidityTimeSwitch"
              v-model="formData.idcardValidityEnd"
              :disabled="MyStatus"
              type="date"
              :placeholder="$t('m.personalcenter_myorder.date_closed')"
              value-format="YYYY-MM-DD"
              size="small"
            />
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.E_mail')" prop="email" v-if="!MyStatus">
            <el-input v-model="formData.email" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Basic_Account_Number')" prop="settleAcctBankAcctNo" v-if="!MyStatus">
            <el-input v-model="formData.settleAcctBankAcctNo" :disabled="MyStatus" size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Bank_Associate_Number')" prop="bankCodeData" v-if="!MyStatus">
            <el-input v-model="formData.bankCodeData" :disabled="MyStatus" size="small" maxlength="12"></el-input>
            <!-- <el-select
              v-model="formData.bankCodeData"
              size="small"
              filterable
              clearable
              remote
              reserve-keyword
              :placeholder="$t('m.personalcenter_myorder.Please_enter_the_bank_name')"
              :remote-method="bankCodeRemoteMethod"
              :loading="bankCodeLoding"
            >
              <el-option
                v-for="item in settleAcctBankBranchCodeList"
                :key="item.bankCode"
                :label="item.lname"
                :value="item.bankCode"
              />
            </el-select> -->
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Funding_Password')" prop="moneypsd">
            <el-input v-model="formData.moneypsd" :disabled="MyStatus"
            type="password"
            maxlength="6"
             size="small"></el-input>
          </el-form-item>
          <el-form-item :label="$t('m.personalcenter_myorder.Confirm_Funds_Password')" prop="resultMoneypsd" v-if="!MyStatus">
            <el-input v-model="formData.resultMoneypsd"
            type="password"
            maxlength="6"
             size="small"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="from_pic" v-if="!MyStatus">
      <h2>{{radio==1?$t('m.personalcenter_myorder.Upload_ID_card'):$t('m.personalcenter_myorder.Upload_enterprise_information')}}</h2>
      <div class="from_geren">
        <ul class="show_img_ul">
          <li class="show_img_li"
          v-for="(i,k) in imgList" :key="k">
            <el-upload
              list-type="picture"
              :before-upload="beforeUpload"
              :on-success="guigeuploadSuccess"
              :on-preview="handlePictureCardPreview"
              :data="upLoadData"
              :action="upLoadUrl"
              :on-remove="guigehandleRemove"
              :on-change="guigeonChange"
              accept="jpg/jpeg/png"
            >
            <img @click="imgIndex=k" :src="i.img||'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/unimags.png'" alt="">
            </el-upload>
            <span>{{i.msg}}</span>
          </li>
          <!-- <li>
            <img src="../../static/ossImg/sfz_guo.png" alt="">
            <span>身份证国徽像</span>
          </li>
          <li>
            <img src="../../static/ossImg/sfz_hand.png" alt="">
            <span>手持身份证</span>
          </li> -->
        </ul>
      </div>
    </div>
  </div>

  <div class="submit" v-if="!MyStatus">
    <div class="tiaoyue">
      <el-checkbox  v-model="checkedTiaoYue" size="mini">
        {{$t('m.loginandregister.Read_and_agree')}}
        <span class="tiaokuan" @click="readContract()">《{{$t('m.personalcenter_myorder.EXP2C_Fund_account_service_agreement')}}》</span>
      </el-checkbox>
    </div>
    <el-button @click="submitForm" :disabled="!checkedTiaoYue">{{$t('m.personalcenter_myorder.Submit_for_review')}}</el-button>
  </div>
  <!-- 验证资金弹框 -->
  <div>
    <el-dialog
      v-model="dialogVisible_money"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      title="验证"
      width="30%"
      :before-close="handleClose"
    >
      <div class="pay_body">
        <el-input v-model="inputMoney" @change="confirmblAdbill('inputMoney')"
          maxlength="8"
        placeholder="请输入验证金额">
          <template #prepend>验证金额</template>
        </el-input>

        <el-button class="subtn"
          @click="topupMoney"
          :disabled="disabled_money"
          size="">确认验证</el-button>
      </div>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { ElMessage } from 'element-plus'
import http from '../../api/http'
import { getStore } from '../../tools/storage'
import { ref } from 'vue-demi'

// 开通资金账户接口
export const openCapitalNoApply = (data) => {
  return http.post('mall-finance/finance/capital/openCapitalNoApply', data, true)
}
// 查看是否绑定了资金账户
export const lookbd = (userAccount, data) => {
  return http.get(`mall-finance/finance/capital/getCustomerInfo/${userAccount}`, data)
}
// 江苏银行上传图片接口
export const upLoadImage = (data) => {
  return http.post('mall-finance/jiangSuBank/upLoadImage', data, true, true)
}
// 江苏银行开户状态查询-系统订单号
export const signStatusQueryBySysId = (data) => {
  return http.post('mall-finance/jiangSuBank/signStatusQueryBySysId', data)
}
// 江苏银行模糊查银行
export const selectListByName = (name) => {
  return http.get(`mall-finance/finance/fmsJsBankPaymentLines/selectListByName/${name}`)
}
// 开户回填验证
const realNameAuth = (data) => {
  return http.post('mall-finance/finance/capital/realNameAuth', data)
}
export default {
  name: 'Money',
  data () {
    return {
      regMoney: /^([0-9]{1}|^[1-9]{1}\d{1,15})(\.\d{1,2})?$/, // 金额正则
      logText: '你尚未开通资金账户，请开通',

      MyStatus: null, // 判断是否开通资金账户
      thisStatus: null, // 资金账户当前状态 0 未审核，1已审核，2待账户验证，3已打款待审核，4被驳回
      customerType: null, // 账户类型
      loadingMystatus: true, // 资金账户按钮
      radio: '2', // 选择的开通方式
      imgIndex: '', // 选择的图
      imgList: [
        { img: '', msg: this.$t('m.personalcenter_myorder.Upload_authorization_certificate') },
        { img: '', msg: this.$t('m.personalcenter_myorder.Upload_your_business_license') },
        { img: '', msg: this.$t('m.personalcenter_myorder.Id_card_face') },
        { img: '', msg: this.$t('m.personalcenter_myorder.National_identity_card_emblem') },
        { img: '', msg: this.$t('m.personalcenter_myorder.Hold_an_ID_card') },
        { img: '', msg: this.$t('m.personalcenter_myorder.Basic_account_of_the_enterprise') }
      ],
      settleAcctBankBranchCodeList: [], // 开户银行联行号列表
      bankCodeLoding: false,
      businessTimeSwitch: false, // 营业期限有效期选择
      organizationCerTimeSwitch: false, // 组织机构证书有效期选择
      idcardValidityTimeSwitch: false, // 身份证有效期选择
      formData: {
        name: '', // 姓名 && 法人姓名
        identity: '', // 身份证号 && 法人身份证号
        idcardValidityBegin: '', // 身份证有效期开始时间
        idcardValidityEnd: '', // 身份证有效期结束时间
        email: '', // 邮箱
        phone: '', // 手机号
        moneypsd: '', // 资金密码 && 企业资金密码
        resultMoneypsd: '', // 确认资金密码 && 企业确认资金密码

        // 企业
        qyName: '', // 企业名称
        enterpriseAddress: '', // 企业地址
        // businessLicenseValidTime: '', // 营业期限
        businessLicenseValidBegin: '', // 营业期限开始
        businessLicenseValidEnd: '', // 营业期限结束
        settleAcctBankAcctNo: '', // 基本户账号
        bankCodeData: '', // 开户银行联行号
        qyNumber: '', // 社会统一认证代码
        organizationCertNumber: '', // 组织机构代码
        // organizationCertValidTime: '', // 组织机构证书有效期
        organizationCertValidBegin: '', // 组织机构证书有效期开始
        organizationCertValidEnd: '', // 组织机构证书有效期结束
        legalPersonIdCardCopy: '', // 身份证正面(江苏银行返回地址)
        legalPersonIdCardNational: '', // 身份证反面(江苏银行返回地址)
        businessLicenseCopy: '', // 营业执照(江苏银行返回地址)
        operatorName: '', // 经办人姓名
        operatorPhoneNumber: '', // 经办人手机号
        operatorIDNumberNo: '' // 经办人身份证号
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        identity: [{ required: true, message: '请输入正确的身份证号', trigger: 'blur' }],
        idcardValidityBegin: [{ required: true, message: '请选择身份证有效期', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入正确的手机号', trigger: 'blur' }],
        moneypsd: [{ required: true, message: '请输入资金密码', trigger: 'blur' }],
        resultMoneypsd: [{ required: true, message: '请确认资金密码', trigger: 'blur' }],
        qyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        enterpriseAddress: [{ required: true, message: '请输入企业地址', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        settleAcctBankAcctNo: [{ required: true, message: '请输入基本户账号', trigger: 'blur' }],
        bankCodeData: [{ required: true, message: '请输入开户银行联行号', trigger: 'blur' }],
        organizationCertNumber: [{ required: true, message: '请输入组织机构代码', trigger: 'blur' }],
        qyNumber: [{ required: true, message: '请输入正确的企业认证代码', trigger: 'blur' }],
        operatorName: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' }], // 经办人姓名
        operatorPhoneNumber: [{ required: true, message: '请输入正确的经办人手机号', trigger: 'blur' }], // 经办人手机号
        operatorIDNumberNo: [{ required: true, message: '请输入正确的经办人身份证号', trigger: 'blur' }] // 经办人身份证号
        // businessLicenseValidTime: [{ message: '请选择营业期限', trigger: 'blur' }]
      }, // 数据规则

      checkedTiaoYue: ref(false),
      dialogVisible_money: ref(false), // 弹窗dialog
      inputMoney: null, // 验证金额
      disabled_money: ref(false), // 按钮禁用

      // 上传数据
      dir: null,
      fileList: [],
      upLoadUrl: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com',
      upLoadData: {
        key: null,
        policy: null,
        OSSAccessKeyId: null,
        success_action_status: 200, // 让服务端返回200，否则默认返回204。
        signature: null
      }
    }
  },
  components: {},
  computed: {
    showFileList: {
      get: function () {
        return this.value !== null && this.value !== '' && this.value !== undefined
      },
      set: function (newValue) {
      }
    }
  },
  methods: {
    readContract (code) {
      window.open('https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/e%E8%B4%B8%E7%BD%91%E8%B5%84%E9%87%91%E8%B4%A6%E6%88%B7%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.pdf')
    },
    isradio (e) {
      // console.log(e)
      if (e === '2') {
        this.imgList = [
          { img: '', msg: this.$t('m.personalcenter_myorder.Upload_authorization_certificate') },
          { img: '', msg: this.$t('m.personalcenter_myorder.Upload_your_business_license') },
          { img: '', msg: this.$t('m.personalcenter_myorder.Id_card_face') },
          { img: '', msg: this.$t('m.personalcenter_myorder.National_identity_card_emblem') },
          { img: '', msg: this.$t('m.personalcenter_myorder.Hold_an_ID_card') },
          { img: '', msg: this.$t('m.personalcenter_myorder.Basic_account_of_the_enterprise') }
        ]
      } else {
        this.imgList = [
          { img: '', msg: this.$t('m.personalcenter_myorder.Id_card_face') },
          { img: '', msg: this.$t('m.personalcenter_myorder.National_identity_card_emblem') },
          { img: '', msg: this.$t('m.personalcenter_myorder.Hold_an_ID_card') }
        ]
      }
    },
    getTimeMap () {
      // console.log(this.formData.businessLicenseValidTime)
    },
    yzMoneyBtn () {
      this.dialogVisible_money = true
    },
    async topupMoney () { // 确认验证
      this.disabled_money = true
      const data = {
        amount: Number(this.inputMoney),
        customerAccount: JSON.parse(getStore('usermessage')) ? JSON.parse(getStore('usermessage')).userAccount : null
      }
      const res = await realNameAuth(data)
      console.log(res)
      if (res.data.code === 200) {
        this.dialogVisible_money = false
        this.inputMoney = ''
        ElMessage.success({
          message: res.data.msg
        })
      } else {
        this.dialogVisible_money = false
        this.disabled_money = false
        ElMessage.warning({
          message: res.data.msg
        })
      }
    },
    handleClose () { // 关闭弹窗
      this.dialogVisible_money = false
    },
    async bankCodeRemoteMethod (query) { // 模糊查询银行
      console.log(query)
      if (query === '' || query === null) {
        return
      }
      this.bankCodeLoding = true
      const res = await selectListByName(query)
      console.log(res)
      if (res.data.code === 200) {
        this.settleAcctBankBranchCodeList = res.data.data
        this.bankCodeLoding = false
      } else {
        ElMessage.error({
          message: res.data.msg
        })
        this.bankCodeLoding = false
      }
    },
    confirmblAdbill (data) {
      switch (data) {
        case 'sfz': // 身份证校验
          if (!/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.formData.identity)) {
            this.formData.identity = ''
          }
          break
        case 'inputMoney':
          this.inputMoney = (parseInt(this.inputMoney * 10000) / 10000).toFixed(2)

          if (!this.regMoney.test(this.inputMoney)) {
            this.inputMoney = ''
          }
          break
        default:
          break
      }
    },
    beforeUpload (file) { // 上传图片触发方法
      console.log(file)
      console.log(this.imgIndex)
      // const ttdata = {
      //   sysId: '12354545454'
      // }

      // const ttpost = signStatusQueryBySysId(ttdata)
      // console.log(ttpost)

      if (this.imgIndex === 1 || this.imgIndex === 2 || this.imgIndex === 3) {
        const testfdata = new FormData()
        testfdata.append('file', file)
        testfdata.append('fileName', file.name)
        const test = upLoadImage(testfdata)
        test.then(res => {
          console.log(res)
          if (res.data.code === 200) {
            switch (this.imgIndex) {
              case 1:
                this.formData.businessLicenseCopy = res.data.data.media_id
                break
              case 2:
                this.formData.legalPersonIdCardCopy = res.data.data.media_id
                break
              case 3:
                this.formData.legalPersonIdCardNational = res.data.data.media_id
                break
              default:
                break
            }
            // if (this.imgIndex === 2) {
            //   this.formData.legalPersonIdCardCopy = res.data.data.media_id
            // } else if (this.imgIndex === 3) {
            //   this.formData.legalPersonIdCardNational = res.data.data.media_id
            // } else {
            //   this.formData.businessLicenseCopy = res.data.data.media_id
            // }
          }
        }).catch(err => {
          console.log(err)
        })
      }

      // console.log(test)

      // this.fileName = file.uid + file.name.substring(file.name.lastIndexOf('.'))
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'jpg'
      const extension2 = testmsg === 'png'
      const extension3 = testmsg === 'jpeg'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 < 20480 // 这里做文件大小限制 // 暂时取消限制
      if (!extension && !extension2 && !extension3) {
        ElMessage({
          message: '上传文件只能是 jpg、png、jpeg格式!',
          type: 'warning'
        })
        return
      }
      if (!isLt2M) { // 暂时取消限制
        ElMessage({
          message: '上传文件大小不能超过20M!',
          type: 'warning'
        })
        return
      }
      const that = this
      const param = {
        prefix: 'userMoney/'
      }
      return new Promise((resolve, reject) => {
        http.get('mall-admin/aliyun/oss/policy', param)
          .then(res => {
            // console.log(res)
            that.dir = res.data.data.dir
            that.upLoadData.key = `${res.data.data.dir}/${file.name}`
            that.upLoadData.policy = res.data.data.policy
            that.upLoadData.OSSAccessKeyId = res.data.data.accessKeyId
            that.upLoadData.signature = res.data.data.signature
            that.upLoadUrl = res.data.data.host
            // this.formData.specification_listData[this.imgIndex].price = file
            // console.log(that.upLoadData)
            // this.imgList[this.imgIndex].img = this.upLoadUrl + '/' + this.upLoadData.key

            // console.log(this.formData.specification_listData)
            resolve(true)
          }).catch(err => {
            console.log(err)
            reject(err)
          })
      })
    },
    guigeuploadSuccess (res, file) { // 图片改变触发方法
      // console.log(file)
      // this.showFileList = true
      const url = this.upLoadUrl + '/' + this.dir + '/' + file.name // 拼接oss路径
      setTimeout(() => {
        // this.imgList[this.imgIndex].img = data.action + '/' + data.data.key/
        this.imgList[this.imgIndex].img = url
      }, 1000)
      // console.log(this.imgList)
    },
    handlePictureCardPreview (file) { // 图片放大方法
      console.log(file)
      // this.formData.decript.dialogImageUrl = file.url
      // this.formData.decript.dialogVisible = true // 打开弹窗
    },
    guigehandleRemove (file) { // 删除图

    },
    guigeonChange (file, fileList) { // 暂时用不到
      // if (fileList.length > 1) {
      //   fileList.splice(0, 1)
      // }
      // const url = this.upLoadUrl + '/' + this.dir + '/' + file.name
      // this.imgList[this.imgIndex].img = url
    },
    async submitForm () { // 提交数据
      this.$refs.formData.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '确认中'
          })
          // console.log('继续跑')
          let data = {}
          const userAccount = JSON.parse(getStore('usermessage')).userAccount
          const referrerId = getStore('usermessage').referrerId || 100000
          if (this.formData.moneypsd !== this.formData.resultMoneypsd) {
            ElMessage.warning({
              message: '两次资金密码不一致!!'
            })
            loading.close()
            return
          }
          if (!/^[0-9]*$/.test(this.formData.moneypsd) || !/^[0-9]*$/.test(this.formData.resultMoneypsd)) {
            ElMessage.warning({
              message: '请输入正确的纯数字密码'
            })
            return
          }
          if (this.MyStatus) {
            loading.close()
            ElMessage.warning({
              message: '请勿重复开通'
            })
          }
          console.log('继续跑')
          if (this.radio === '1') {
            data = {
              name: this.formData.name, // 开户姓名
              capitalPassword: this.formData.moneypsd, // 资金密码
              capitalPrefix: referrerId,
              cardImgFront: this.imgList[0].img, // 头像面
              cardImgReverse: this.imgList[1].img, // 国徽面
              cardImgSc: this.imgList[2].img, // 手持面
              idCard: this.formData.identity, // 身份证号码
              customerAccount: userAccount, // 用户账户
              telNumber: this.formData.phone, // 手机号码
              serviceAgreement: 1,
              customerType: 0 // 个人
            }
          } else {
            // console.log(this.bankCodeData)
            data = {
              name: this.formData.name, // 法人姓名
              operatorName: this.formData.operatorName, // 经办人姓名
              operatorPhoneNumber: this.formData.operatorPhoneNumber, // 经办人手机号
              operatorIDNumberNo: this.formData.operatorIDNumberNo, // 经办人身份证号
              capitalPassword: this.formData.moneypsd, // 资金密码
              capitalPrefix: referrerId,
              cardImgFront: this.imgList[2].img, // 头像面
              cardImgReverse: this.imgList[3].img, // 国徽面
              cardImgSc: this.imgList[4].img, // 手持面
              idCard: this.formData.identity, // 法人身份证号码
              idcardValidityBegin: this.idcardValidityTimeSwitch ? '长期' : this.formData.idcardValidityBegin,
              idcardValidityEnd: this.idcardValidityTimeSwitch ? '长期' : this.formData.idcardValidityEnd,
              email: this.formData.email, // 邮箱
              customerAccount: userAccount, // 用户账户
              telNumber: this.formData.phone, // 手机号码
              enterpriseName: this.formData.qyName, // 企业名称
              businessLicenseValidTime: this.businessTimeSwitch ? (this.formData.businessLicenseValidBegin + ',长期') : (this.formData.businessLicenseValidBegin + ',' + this.formData.businessLicenseValidEnd), // 营业期限
              enterpriseAddress: this.formData.enterpriseAddress, // 企业地址
              businessLicense: this.imgList[1].img, // 营业执照
              powerOfAttorney: this.imgList[0].img, // 授权书
              basicAccountPicture: this.imgList[5].img, // 基本户图片
              settleAcctBankAcctNo: this.formData.settleAcctBankAcctNo, // 基本户账号
              settleAcctBankBranchCode: this.formData.bankCodeData, // 开户银行联行号
              legalPersonIdCardCopy: this.formData.legalPersonIdCardCopy, // 身份证正面(江苏银行返回地址)
              legalPersonIdCardNational: this.formData.legalPersonIdCardNational, // 身份证反面(江苏银行返回地址)
              businessLicenseCopy: this.formData.businessLicenseCopy, // 营业执照(江苏银行返回地址)
              shtyrzCode: this.formData.qyNumber, // 社会统一认证代码
              organizationCertNumber: this.formData.organizationCertNumber, // 组织机构代码
              organizationCertValidTime: this.organizationCerTimeSwitch ? (this.formData.organizationCertValidBegin + ',长期') : (this.formData.organizationCertValidBegin + ',' + this.formData.businessLicenseValidEnd), // 组织机构证书有效期
              serviceAgreement: 1, // 是否同意E贸网用户协议
              customerType: 1 // 企业法人
            }
            console.log(data)
          }
          const res = openCapitalNoApply(data)
          res.then(resolve => {
            console.log(resolve)
            if (resolve.data.code === 200) {
              ElMessage.success({
                message: resolve.data.msg
              })
              this.isradio(this.radio)
              this.formData = {
                name: '', // 姓名 && 法人姓名
                identity: '', // 身份证号 && 法人身份证号
                idcardValidityBegin: '', // 身份证有效期开始时间
                idcardValidityEnd: '', // 身份证有效期结束时间
                email: '', // 邮箱
                phone: '', // 手机号
                moneypsd: '', // 资金密码 && 企业资金密码
                resultMoneypsd: '', // 确认资金密码 && 企业确认资金密码

                // 企业
                qyName: '', // 企业名称
                operatorName: '', // 经办人姓名
                operatorPhoneNumber: '', // 经办人手机号
                operatorIDNumberNo: '', // 经办人身份证号
                enterpriseAddress: '', // 企业地址
                // businessLicenseValidTime: '', // 营业期限
                businessLicenseValidBegin: '', // 营业期限开始
                businessLicenseValidEnd: '', // 营业期限结束
                settleAcctBankAcctNo: '', // 基本户账号
                bankCodeData: '', // 开户银行联行号
                qyNumber: '', // 社会统一认证代码
                organizationCertNumber: '', // 组织机构代码
                // organizationCertValidTime: '', // 组织机构证书有效期
                organizationCertValidBegin: '', // 组织机构证书有效期开始
                organizationCertValidEnd: '', // 组织机构证书有效期结束
                legalPersonIdCardCopy: '', // 身份证正面(江苏银行返回地址)
                legalPersonIdCardNational: '', // 身份证反面(江苏银行返回地址)
                businessLicenseCopy: '' // 营业执照(江苏银行返回地址)
              }
            } else {
              ElMessage.warning({
                message: resolve.data.msg
              })
            }
            loading.close()
          }).catch(err => {
            console.log(err)
            loading.close()
          })
        } else {

        }
      })
    },
    async getMonetStatus (userAccount) { // 获取是否开通资金账户
      const res = await lookbd(userAccount.userAccount, {})
      console.log(res)
      if (res.data.code === 200) {
        // console.log(res)
        const mess = res.data.data
        this.MyStatus = true
        this.customerType = res.data.data.customerType // 0：个人， 1：企业（法人），2：企业账号认证
        this.thisStatus = mess.status // 0 未审核，1已审核，2待账户验证，3被驳回
        this.formData.name = mess.name.replace(/^(.).+$/, '$1**')
        this.formData.identity = mess.idCard.replace(/(?<=\d{3})\d{12}(?=\d{2})/, '************')
        this.formData.moneypsd = '*******'
        this.formData.qyName = mess.enterpriseName
        this.formData.qyNumber = mess.shtyrzCode
        this.formData.enterpriseAddress = mess.enterpriseAddress
        this.formData.idcardValidityBegin = mess.idcardValidityBegin
        this.formData.idcardValidityEnd = mess.idcardValidityEnd
        this.formData.operatorName = mess.operatorName // 经办人姓名
        this.formData.operatorPhoneNumber = mess.operatorPhoneNumber // 经办人手机号
        // this.customerType === 0 ?:
        if (this.customerType === 0) {
          this.radio = '1'
        } else {
          this.radio = '2'
        }
        ElMessage.success({
          message: res.data.msg
        })
        // if (res.data.data){

        // }
        this.loadingMystatus = false
      } else {
        if (res.data.code === 500) {
          ElMessage.warning({
            message: res.data.msg
          })
          this.logText = res.data.msg
          this.loadingMystatus = false
          return
        }
        ElMessage.warning({
          message: res.data.msg
        })
        this.loadingMystatus = false
      }
    }
  },
  mounted () {
    const userAccount = JSON.parse(getStore('usermessage'))
    this.getMonetStatus(userAccount)
    // console.log(userAccount)
    this.formData.phone = userAccount.phone
  },
  setup (props, ctx) {
    const pickerOptions = {
      disabledNtomDate: (time) => {
        // 此条为设置禁止用户选择今天之后的日期，包含今天。
        return time.getTime() > Date.now()
      }
    }
    return {
      pickerOptions
    }
  }
}
</script>
<style lang='scss'>
@import '../../style/viriables.scss';
.main{
  font-family: Microsoft YaHei;
  .zijin_top{
    h2{
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;
      color: $title;
      padding: 0 11px;
    }
    .zijin_title{
      display: flex;
      align-items: center;
      height: 40px;
      background: #ebebeb;
      color: #E61F19;
      .el-icon{
        font-size: 18px;
        padding: 0 11px;
      }
      .el-button{
        margin-left: 20px;
        span{
          font-size: 14px;
        }
        .el-icon{
          font-size: 14px;
          padding: 0;
        }
      }
    }
    .zijin_chose{
      margin: 0 23px;
      height: 80px;
      padding: 0 22px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      .chose_span{
        margin-right: 70px;
      }
    }
  }
  .zijin_body{
    .from_wenzi,.from_pic{
      h2{
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        font-weight: bold;
        color: $title;
        padding: 0 11px;
      }
      .from_geren{
        .el-form{
          .el-form-item{
            margin-bottom: 15px;
            .el-form-item__content{
              .el-input{
                max-width: 300px;
                // width: 300px;
              }
              .el-form-item__error{
                padding: 0;
                // margin-top: -5px;
                z-index: 2010;
              }
              .el-select{
                width: 400px;
                .el-input{
                  max-width: 400px;
                  // width: 300px;
                }
              }
              .time_switch{
                display: flex;
                align-items: center;
              }
            }
          }
        }
        .show_img_ul{
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .show_img_li{
            width: 33%;
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-bottom: 30px;
            img{
              width: 187px;
              height: 127px;
            }
            span{
              margin: 10px;
              font-size: 14px;
              color: #333333;
            }
            div{
              width: 187px;
              height: 127px;
              .el-upload--picture-card{
                background: transparent;
                border: none;
                width: 100% !important;
                height: 100% !important;
              }
              .el-upload-list{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .submit{
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .tiaoyue{
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      .tiaokuan{
        color: #C6824E;
      }
    }
    .el-button{
      background: $title;
      color: #ebebeb;
    }
  }
  .el-overlay{
    .el-dialog{
      .el-dialog__body{
        .subtn{
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
