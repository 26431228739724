<template>
<div class='main'>
  <div class="security_top">
    <h2>{{$t('m.personalcenter_myorder.Security_settings')}}</h2>
  </div>
    <!-- <el-divider></el-divider> -->
  <div class="security_body">
    <ul>
      <li>
        <strong>{{$t('m.personalcenter_myorder.Mobile_Number')}}</strong>
        <p>{{phoneinput}}</p>
        <el-button :disabled="true">{{$t('m.personalcenter_myorder.Modify_mobile_phone_number')}}</el-button>
      </li>
      <li>
        <strong>{{$t('m.personalcenter_myorder.Login_password')}}</strong>
        <p>************</p>
        <el-button @click="undate('loginPwd')">{{$t('m.personalcenter_myorder.Changing_the_login_Password')}}</el-button>
      </li>
      <li>
        <strong>{{$t('m.personalcenter_myorder.payment_password')}}</strong>
        <p>*******</p>
        <el-button @click="undate('buyPwd')">{{$t('m.personalcenter_myorder.Change_payment_password')}}</el-button>
      </li>
    </ul>
  </div>
  <!-- 修改信息弹窗 -->
  <div v-if="ConfirmDialog" class="dialog_div">
    <el-dialog v-model="ConfirmDialog" width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
        destroy-on-close
        title="修改信息">

        <div class="namebody" v-if="ischose == 'loginPwd'">
          <el-input
          v-model="phoneinput" placeholder="请输入手机号">
            <template #append>
              <el-button type="warning" size="small"
              :disabled="checkCodeBtn.disabled"
              @click="getCheckCode">
              {{checkCodeBtn.text}}</el-button>
              </template>
          </el-input>
          <el-input
            v-model="codeValue"
            size="small"
            placeholder="请输入验证码"
            maxlength="6">
          </el-input>
          <el-input
            v-model="loginPwd"
            size="small" show-password
            placeholder="请输入8-16位字母+数字新密码"
            maxlength="16">
          </el-input>
          <el-button :loading="loadingbtn"
          @click="ConfirmInvoiceBtn">确认</el-button>
        </div>

        <div class="namebody" v-else-if="ischose == 'buyPwd'">
          <el-input
            v-model="buyPwd"
            size="small" show-password
            placeholder="请输入旧密码"
            maxlength="6">
          </el-input>
          <el-input
            v-model="buyPwdNew"
            size="small" show-password
            placeholder="请输入新密码"
            maxlength="6">
          </el-input>
          <el-button :loading="loadingbtn"
          @click="ConfirmInvoiceBtn">确认</el-button>
        </div>
        <div class="namebody" v-else>
          暂不支持！
        </div>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { getCurrentInstance, reactive, ref } from 'vue-demi'
import { ElMessage } from 'element-plus'
import http from '../../api/http'
import { getStore } from '../../tools/storage'
// import RetrievePwd from '../loginandregister/RetrievePwd.vue'

// 修改登录密码
const forgetPassword = (data) => {
  return http.post('/mall-portal/sso/forgetPassword', data, false)
}
// 修改支付密码
const updatePassword = (data) => {
  return http.post('/mall-portal/sso/updatePayFeginPassword', data)
}
export default {
  name: 'securitySettings',
  data () {
    return {
      usermessage: ref(), // 用户登录信息
      ConfirmDialog: ref(false), // 修改弹窗开关
      ischose: '',
      phoneinput: (''), // 用户手机号
      codeValue: ref(''), // 验证码
      loginPwd: ref(''), // 用户登录密码

      buyPwd: ref(''), // 用户支付密码
      buyPwdNew: ref(''), // 用户支付新密码
      loadingbtn: ref(false)
    }
  },
  components: {},
  methods: {
    undate (isc) { // 修改信息
      this.ischose = isc
      this.ConfirmDialog = true
    },
    async ConfirmInvoiceBtn () { // 弹窗确定按钮
      if (this.ischose === 'loginPwd') { // 修改登陆密码
        const userPwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
        if (!userPwd.test(this.loginPwd)) {
          ElMessage.warning({
            message: '请正确填写密码（含有数字和字母，且长度要在8-16位之间）'
          })
          this.loginPwd = ''
          return
        }
        const data = {
          telephone: this.phoneinput,
          password: this.loginPwd,
          authCode: this.codeValue
        }
        const res = await forgetPassword(data)
        console.log(res)
        if (res.data.msg === '验证失败，请重新发送验证码') {
          // ElMessage.error
          this.authCode = ''
        } else if (res.data.msg === '"验证码错误"') {
          this.authCode = ''
        } else if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.$router.push('/loginandregister')
        }
      } else if (this.ischose === 'buyPwd') { // 修改支付密码
        if (!/^[0-9]*$/.test(this.buyPwd) || !/^[0-9]*$/.test(this.buyPwdNew)) {
          ElMessage.warning({
            message: '请输入正确的纯数字密码'
          })
          return
        }
        const capitalAccount = JSON.parse(getStore('usermessage')).capitalAccount
        const passwordOld = this.buyPwd
        const passwordNew = this.buyPwdNew
        // console.log(capitalAccount)
        const md5passwordOld = this.$md5(`${capitalAccount}${passwordOld}`)
        const md5passwordNew = this.$md5(`${capitalAccount}${passwordNew}`)
        const data = {
          capitalAccount: capitalAccount,
          newPassword: md5passwordNew,
          oldPassword: md5passwordOld
        }
        const res = await updatePassword(data)
        console.log(res)
        if (res.data.code === 200) {
          ElMessage.success({
            message: res.data.msg
          })
          this.buyPwd = ''
          this.buyPwdNew = ''
          this.ConfirmDialog = false
        } else {
          ElMessage.warning({
            message: res.data.msg
          })
          this.buyPwd = ''
          this.buyPwdNew = ''
        }
      }
    }
  },
  mounted () {
    if (getStore('usermessage')) { // 判断有没有登录信息
      this.userList = JSON.parse(getStore('usermessage'))
      // console.log(this.userList)
      this.phoneinput = this.userList.phone
      // this.Email = null
    } else {
      this.$router.push('/loginandregister')
    }
  },
  setup (props, ctx) {
    const datcop = getCurrentInstance()
    const checkCodeBtn = reactive({
      text: '获取验证码',
      loading: false,
      disabled: false,
      duration: 60,
      timer: null
    })
    // 获取修改密码验证码
    const getCheckCode = () => {
      http.get('/mall-portal/sso/getAuthCode', {
        telephone: String(datcop.data.phoneinput),
        flag: 1,
        type: 'null'
      })
        .then(res => {
          console.log(res)
          if (res.data.code === 200) {
            ElMessage.success({ message: res.data.message })
          }
          if (res.data.message === '该手机已注册过了') {
            clearInterval(checkCodeBtn.timer)
          }
        }).catch(err => {
          console.log(err)
        })
      // 倒计时期间按钮不能单击
      if (checkCodeBtn.duration !== 60) {
        checkCodeBtn.disabled = true
      }
      // 清除掉定时器
      checkCodeBtn.timer && clearInterval(checkCodeBtn.timer)
      // 开启定时器
      checkCodeBtn.timer = setInterval(() => {
        const tmp = checkCodeBtn.duration--
        checkCodeBtn.text = `${tmp}秒`
        if (tmp <= 0) {
          // 清除掉定时器
          clearInterval(checkCodeBtn.timer)
          checkCodeBtn.duration = 60
          checkCodeBtn.text = '重新获取'
          // 设置按钮可以单击
          checkCodeBtn.disabled = false
        }
        // console.log(checkCodeBtn.duration)
      }, 1000)
    }
    return {
      checkCodeBtn, getCheckCode
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
@import '../../style/viriables.scss';
.main{
  font-family: Microsoft YaHei;
  .security_top{
    border-bottom: 2px solid #999999;
    h2{
      height: 42px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;
      color: $title;
      padding: 0 11px;
    }
  }
  .security_body{
    ul{
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li{
        min-width: 200px;
        padding: 0 10px;
        height: 195px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #999999;
        font-size: 16px;
        box-shadow: 1px 1px 4px 0;
        strong{
          font-weight: bold;
          color: #6B0401;
        }
        p{
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
  .dialog_div{
    .el-overlay{
      .el-overlay-dialog{
        .el-dialog{
          .el-dialog__body{
            .namebody{
              .el-input{
                margin-bottom: 20px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
